import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LoginPage from "./features/auth/LoginPage";
// import StartPage from "./pages/StartPage";
import LoadingPage from "./features/auth/LoadingPage";
import OverviewPage from "./pages/OverviewPage";
import IntegrationsComingSoon from "./pages/IntegrationsComingSoon";
import UpgradePlanPage from "./pages/UpgradePlanPage";
import DashLayout from "./components/layout/DashLayout";
import WorkingOnSomething from "./pages/WorkingOnSomething";
import PersistLogin from "./features/auth/PersistLogin";
import "./hotjar";
import FixPage from "./pages/FixPage";

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <Routes>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/working-on-something' element={<WorkingOnSomething />} />
          <Route element={<PersistLogin />}>
            <Route path='/' element={<Navigate to='/dashboard' replace />} />
            {/* <Route path='/start' element={<StartPage />} /> */}
            <Route path='/loading' element={<LoadingPage />} />
            <Route path='/' element={<DashLayout />}>
              <Route path='dashboard' element={<OverviewPage />} />
              <Route path='fixes' element={<FixPage />} />
              <Route path='fixes/:fixId' element={<FixPage />} />
              <Route path='integrations' element={<IntegrationsComingSoon />} />
              <Route path='upgrade-plan' element={<UpgradePlanPage />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
