import DzFlag from "../..//assets/flags/dz-flag.svg";
import ArFlag from "../..//assets/flags/ar-flag.svg";
import AuFlag from "../..//assets/flags/au-flag.svg";
import AtFlag from "../..//assets/flags/at-flag.svg";
import BeFlag from "../..//assets/flags/be-flag.svg";
import BrFlag from "../..//assets/flags/br-flag.svg";
import CnFlag from "../..//assets/flags/cn-flag.svg";
import DkFlag from "../..//assets/flags/dk-flag.svg";
import FiFlag from "../..//assets/flags/fi-flag.svg";
import GlFlag from "../..//assets/flags/gl-flag.svg";
import GtFlag from "../..//assets/flags/gt-flag.svg";
import HkFlag from "../..//assets/flags/hk-flag.svg";
import HuFlag from "../..//assets/flags/hu-flag.svg";
import IsFlag from "../..//assets/flags/is-flag.svg";
import InFlag from "../..//assets/flags/in-flag.svg";
import IdFlag from "../..//assets/flags/id-flag.svg";
import IrFlag from "../..//assets/flags/ir-flag.svg";
import IeFlag from "../..//assets/flags/ie-flag.svg";
import IlFlag from "../..//assets/flags/il-flag.svg";
import ItFlag from "../..//assets/flags/it-flag.svg";
import KzFlag from "../..//assets/flags/kz-flag.svg";
import LbFlag from "../..//assets/flags/lb-flag.svg";
import LuFlag from "../..//assets/flags/lu-flag.svg";
import MxFlag from "../..//assets/flags/mx-flag.svg";
import MdFlag from "../..//assets/flags/md-flag.svg";
import McFlag from "../..//assets/flags/mc-flag.svg";
import MaFlag from "../..//assets/flags/ma-flag.svg";
import NlFlag from "../..//assets/flags/nl-flag.svg";
import NzFlag from "../..//assets/flags/nz-flag.svg";
import NoFlag from "../..//assets/flags/no-flag.svg";
import PeFlag from "../..//assets/flags/pe-flag.svg";
import PtFlag from "../..//assets/flags/pt-flag.svg";
import PrFlag from "../..//assets/flags/pr-flag.svg";
import RoFlag from "../..//assets/flags/ro-flag.svg";
import RuFlag from "../..//assets/flags/ru-flag.svg";
import SaFlag from "../..//assets/flags/sa-flag.svg";
import GbSctFlag from "../..//assets/flags/gb-sct-flag.svg";
import SgFlag from "../..//assets/flags/sg-flag.svg";
import SkFlag from "../..//assets/flags/sk-flag.svg";
import SiFlag from "../..//assets/flags/si-flag.svg";
import ZaFlag from "../..//assets/flags/za-flag.svg";
import KrFlag from "../..//assets/flags/kr-flag.svg";
import EsFlag from "../..//assets/flags/es-flag.svg";
import SeFlag from "../..//assets/flags/se-flag.svg";
import ThFlag from "../..//assets/flags/th-flag.svg";
import TnFlag from "../..//assets/flags/tn-flag.svg";
import TrFlag from "../..//assets/flags/tr-flag.svg";
import UaFlag from "../..//assets/flags/ua-flag.svg";
import AeFlag from "../..//assets/flags/ae-flag.svg";
import UkFlag from "../..//assets/flags/uk-flag.svg";
import UsFlag from "../..//assets/flags/us-flag.svg";
import CaFlag from "../..//assets/flags/ca-flag.svg";
import DeFlag from "../..//assets/flags/de-flag.svg";
import FrFlag from "../..//assets/flags/fr-flag.svg";
import PlFlag from "../..//assets/flags/pl-flag.svg";
import GrFlag from "../..//assets/flags/gr-flag.svg";
import ChFlag from "../..//assets/flags/ch-flag.svg";
import JpFlag from "../..//assets/flags/jp-flag.svg";
import GlobalFlag from "../..//assets/flags/global.svg";

const RegionIndicator = ({ regions }) => {
  const regionArray = Array.isArray(regions) ? regions : [regions];

  const flags = {
    DZ: DzFlag,
    AR: ArFlag,
    AU: AuFlag,
    AT: AtFlag,
    BE: BeFlag,
    BR: BrFlag,
    CN: CnFlag,
    DK: DkFlag,
    FI: FiFlag,
    GL: GlFlag,
    GT: GtFlag,
    HK: HkFlag,
    HU: HuFlag,
    IS: IsFlag,
    IN: InFlag,
    ID: IdFlag,
    IR: IrFlag,
    IE: IeFlag,
    IL: IlFlag,
    IT: ItFlag,
    KZ: KzFlag,
    LB: LbFlag,
    LU: LuFlag,
    MX: MxFlag,
    MD: MdFlag,
    MC: McFlag,
    MA: MaFlag,
    NL: NlFlag,
    NZ: NzFlag,
    NO: NoFlag,
    PE: PeFlag,
    PT: PtFlag,
    PR: PrFlag,
    RO: RoFlag,
    RU: RuFlag,
    SA: SaFlag,
    SCOTLAND: GbSctFlag,
    SG: SgFlag,
    SK: SkFlag,
    SI: SiFlag,
    ZA: ZaFlag,
    KR: KrFlag,
    ES: EsFlag,
    SE: SeFlag,
    TH: ThFlag,
    TN: TnFlag,
    TR: TrFlag,
    UA: UaFlag,
    AE: AeFlag,
    UK: UkFlag,
    US: UsFlag,
    CA: CaFlag,
    DE: DeFlag,
    FR: FrFlag,
    PL: PlFlag,
    GR: GrFlag,
    CH: ChFlag,
    JP: JpFlag,
    GLOBAL: GlobalFlag,
  };

  const getRegionLabel = (code) => {
    const labels = {
      DZ: "Algeria",
      AR: "Argentina",
      AU: "Australia",
      AT: "Austria",
      BE: "Belgium",
      BR: "Brazil",
      CN: "China",
      DK: "Denmark",
      FI: "Finland",
      GL: "Greenland",
      GT: "Guatemala",
      HK: "Hong Kong",
      HU: "Hungary",
      IS: "Iceland",
      IN: "India",
      ID: "Indonesia",
      IR: "Iran",
      IE: "Ireland",
      IL: "Israel",
      IT: "Italy",
      KZ: "Kazakhstan",
      LB: "Lebanon",
      LU: "Luxembourg",
      MX: "Mexico",
      MD: "Moldova",
      MC: "Monaco",
      MA: "Morocco",
      NL: "Netherlands",
      NZ: "New Zealand",
      NO: "Norway",
      PE: "Peru",
      PT: "Portugal",
      PR: "Puerto Rico",
      RO: "Romania",
      RU: "Russia",
      SA: "Saudi Arabia",
      SCOTLAND: "Scotland",
      SG: "Singapore",
      SK: "Slovakia",
      SI: "Slovenia",
      ZA: "South Africa",
      KR: "South Korea",
      ES: "Spain",
      SE: "Sweden",
      TH: "Thailand",
      TN: "Tunisia",
      TR: "Turkey",
      UA: "Ukraine",
      AE: "United Arab Emirates",
      UK: "United Kingdom",
      US: "United States",
      CA: "Canada",
      DE: "Germany",
      FR: "France",
      PL: "Poland",
      GR: "Greece",
      CH: "Switzerland",
      JP: "Japan",
      GLOBAL: "All Regions",
    };
    return labels[code] || code;
  };

  return (
    <div className='group relative bg-[#F8F8FB] px-2.5 py-0.5 rounded-full'>
      <div className='flex items-center'>
        <div className='flex -space-x-2'>
          {regionArray.slice(0, 3).map((region, index) => (
            <img
              key={region}
              src={flags[region]}
              alt={getRegionLabel(region)}
              className='w-5 h-5 rounded-full ring-2 ring-white'
              style={{ zIndex: 3 - index }}
            />
          ))}
        </div>
        <span className='ml-3 text-gray-900'>
          {regionArray.length > 1 ? `${regionArray.length} Regions` : getRegionLabel(regionArray[0])}
        </span>
      </div>

      {/* Hover tooltip */}
      {regionArray.length > 1 && (
        <div className='invisible group-hover:visible absolute left-0 top-full bg-white shadow-lg rounded-lg p-3 z-50 w-max'>
          <div className='flex flex-col gap-1'>
            {regionArray.map((region) => (
              <div key={region} className='flex items-center gap-2'>
                <img src={flags[region]} alt={getRegionLabel(region)} className='w-5 h-5 rounded-full flex-shrink-0' />
                <span className='text-gray-900'>{getRegionLabel(region)}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RegionIndicator;
