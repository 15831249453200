import React, { useState, useEffect } from "react";

const RevenuePotential = ({ value, small = false }) => {
  const [dots, setDots] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev + 1) % 4);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const containerClasses = small
    ? "flex items-center justify-start rounded-full bg-[#40C6A71A] w-[90px] h-[21px] px-2 py-1"
    : "flex items-center justify-start rounded-full bg-[#40C6A71A] w-[130px] h-8 px-4";

  const textClasses = small
    ? "text-[#20AC8C] text-center font-poppins text-xs font-medium leading-4 tracking-[-0.24px]"
    : "text-[#20AC8C] text-center font-poppins text-base font-medium leading-8 tracking-[-0.336px]";

  const formatValue = (val) => {
    if (val >= 1000000) {
      return `${(val / 1000000).toFixed(1)}M`;
    } else if (val >= 1000) {
      return `${(val / 1000).toFixed(1)}k`;
    }
    return val.toFixed(0);
  };

  const lowerBound = value * 0.9;
  const upperBound = value * 1.1;

  return (
    <div className={containerClasses}>
      <span className={textClasses}>
        {value === 0 ? (
          <>Calculating{".".repeat(dots)}</>
        ) : (
          `$${formatValue(lowerBound)} - ${formatValue(upperBound)} /mo`
        )}
      </span>
    </div>
  );
};

export default RevenuePotential;
