import React, { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export const Expander = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleOptionClick = (optionValue) => {
    onChange(optionValue);
    setIsOpen(false);
  };

  return (
    <div className='relative inline-block text-left w-full mb-4'>
      <div>
        <button
          type='button'
          className='inline-flex justify-between w-full rounded-xl border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 '
          id='options-menu'
          aria-haspopup='true'
          aria-expanded={isOpen}
          onClick={toggleOpen}>
          {value === "all"
            ? "All Categories"
            : options.find((option) => option.value === value)?.label}
          {isOpen ? (
            <FiChevronUp className='-mr-1 ml-2 h-5 w-5' aria-hidden='true' />
          ) : (
            <FiChevronDown className='-mr-1 ml-2 h-5 w-5' aria-hidden='true' />
          )}
        </button>
      </div>

      {isOpen && (
        <div className='origin-top-right absolute z-10 right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
          <div
            className='py-1'
            role='menu'
            aria-orientation='vertical'
            aria-labelledby='options-menu'>
            {options.map((option) => (
              <button
                key={option.value}
                onClick={() => handleOptionClick(option.value)}
                className={`${
                  option.value === value ? "bg-gray-100 text-gray-900" : "text-gray-700"
                } block px-4 py-2 text-sm w-full text-left hover:bg-gray-100 hover:text-gray-900`}
                role='menuitem'>
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
