import React, { useEffect, useRef, useState, useMemo } from "react";
import { agent_properties } from "../../../constants/agentConstants";
import { ReactComponent as AIMagicIcon } from "../../../assets/icons/ai/ai-magic-line.svg";
import { Expander } from "../../../components/layout/Expander";
import FixesListCard from "./FixesListCard";
import { IssueStatus } from "../../../constants/issueStatus";

//! Fix List Filters / Header

const AgentDropdown = ({ selectedAgent, setSelectedAgent, fixes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const agentCounts = useMemo(() => {
    const counts = { all: fixes?.length || 0 };
    fixes?.forEach((fix) => {
      counts[fix.ai_agent] = (counts[fix.ai_agent] || 0) + 1;
    });
    return counts;
  }, [fixes]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const selectedAgentProps = selectedAgent !== "all" ? agent_properties[selectedAgent] : null;
  const SelectedIcon = selectedAgentProps?.icon;

  return (
    <div className='relative w-full pr-4' ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className='w-full flex items-center justify-between gap-2 px-4 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white mt-2'>
        <div className='flex items-center gap-2'>
          {selectedAgent !== "all" && SelectedIcon && (
            <div className='p-1 rounded-md text-white' style={{ backgroundColor: selectedAgentProps.color }}>
              <SelectedIcon className='w-4 h-4' />
            </div>
          )}
          <span>{selectedAgent === "all" ? "All Agents" : selectedAgentProps?.name}</span>
        </div>
        <div className='flex items-center gap-2'>
          <span className='text-gray-500 text-sm'>{agentCounts[selectedAgent]}</span>
          <svg className='w-4 h-4 text-gray-400' viewBox='0 0 20 20' fill='currentColor'>
            <path
              fillRule='evenodd'
              d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
              clipRule='evenodd'
            />
          </svg>
        </div>
      </button>

      {isOpen && (
        <div className='absolute z-50 w-full mt-1 bg-white border border-gray-300 rounded-xl shadow-lg'>
          <div className='py-1'>
            <button
              onClick={() => {
                setSelectedAgent("all");
                setIsOpen(false);
              }}
              className='w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center whitespace-nowrap'>
              <div className='flex items-center gap-2'>
                <span>All Agents</span>
                <span className='text-gray-500 text-sm'>({agentCounts.all})</span>
              </div>
            </button>
            {Object.entries(agent_properties).map(([key, agent]) => {
              const AgentIcon = agent.icon;
              return (
                <button
                  key={key}
                  onClick={() => {
                    setSelectedAgent(key);
                    setIsOpen(false);
                  }}
                  className='w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center whitespace-nowrap'>
                  <div className='flex items-center gap-2'>
                    <div className='p-1 rounded-md text-white' style={{ backgroundColor: agent.color }}>
                      <AgentIcon className='w-4 h-4' />
                    </div>
                    <span>{agent.name}</span>
                    <span className='text-gray-500 text-sm'>({agentCounts[key] || 0})</span>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const FixesListHeader = ({
  fixes,
  activeFilter,
  onFilterChange,
  searchTerm,
  setSearchTerm,
  selectedAgent,
  setSelectedAgent,
}) => {
  const FILTERS = [
    { id: "all", label: "All", count: fixes?.length || 0 },
    {
      id: IssueStatus.TO_DO,
      label: "To Do",
      count: fixes?.filter((fix) => fix.status === IssueStatus.TO_DO).length || 0,
    },
    {
      id: IssueStatus.IN_PROGRESS,
      label: "In Progress",
      count: fixes?.filter((fix) => fix.status === IssueStatus.IN_PROGRESS).length || 0,
    },
    { id: IssueStatus.DONE, label: "Done", count: fixes?.filter((fix) => fix.status === IssueStatus.DONE).length || 0 },
  ];

  const uniqueTags = useMemo(() => {
    if (!fixes) return new Set();
    return new Set(fixes.flatMap((fix) => fix.tags || []));
  }, [fixes]);

  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const hasTags = fixes?.some((fix) => fix.tags?.length > 0);

  return (
    <div className='z-10'>
      <div className='flex flex-wrap gap-2 items-center mb-4'>
        <div className='flex flex-wrap gap-2'>
          {FILTERS.map(({ id, label, count }) => (
            <FilterButton
              key={id}
              option={{ id, label }}
              count={count}
              isSelected={activeFilter === id}
              onClick={() => onFilterChange(id)}
            />
          ))}
        </div>
        <AgentDropdown selectedAgent={selectedAgent} setSelectedAgent={setSelectedAgent} fixes={fixes} />
      </div>

      {hasTags && (
        <div className='relative'>
          <input
            type='text'
            placeholder='Search by tags...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setIsSearchFocused(true)}
            onBlur={() => setTimeout(() => setIsSearchFocused(false), 200)}
            className='w-full px-4 py-2 mb-4 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500'
          />
          {isSearchFocused && (
            <TagSuggestions
              tags={Array.from(uniqueTags)}
              searchTerm={searchTerm}
              onSelect={(tag) => {
                setSearchTerm(tag);
                setIsSearchFocused(false);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

const FilterButton = ({ option, count, isSelected, onClick }) => (
  <div
    className={`flex items-center cursor-pointer p-2 gap-2 ${
      isSelected ? "bg-white border-2 border-gray-200 rounded-xl shadow-md" : ""
    }`}
    onClick={onClick}>
    {option.id === "ai" && (
      <span className='bg-ai-purple p-1 rounded-md'>
        <AIMagicIcon className='w-4 h-4 text-white' />
      </span>
    )}
    <span className={`text-sm font-medium ${isSelected ? "" : "text-gray-500"}`}>{option.label}</span>
    <span className='bg-gray-200 text-gray-500 px-2.5 py-0.5 rounded-md text-sm font-medium'>{count}</span>
  </div>
);

//! Fix List

function FixesList({ fixes, selectedFix, onFixClick, onFixesFiltered }) {
  const [activeFilter, setActiveFilter] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAgent, setSelectedAgent] = useState("all");
  const selectedItemRef = useRef(null);

  const filteredFixes = useMemo(() => {
    if (!fixes) return [];

    return fixes
      .sort((a, b) => {
        const statusOrder = { to_do: 0, in_progress: 1, done: 2, discarded: 3 };
        const severityOrder = { high: 0, medium: 1, low: 2 };

        if (a.status !== b.status) return statusOrder[a.status] - statusOrder[b.status];
        if (a.severity !== b.severity) return severityOrder[a.severity] - severityOrder[b.severity];
        return a.shortlink_url.localeCompare(b.shortlink_url);
      })
      .filter((fix) => {
        const matchesFilter = activeFilter === "all" || fix.status === activeFilter;
        const matchesSearch =
          !searchTerm.trim() || fix.tags?.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()));
        const matchesAgent = selectedAgent === "all" || fix.ai_agent === selectedAgent;

        return matchesFilter && matchesSearch && matchesAgent;
      });
  }, [fixes, activeFilter, searchTerm, selectedAgent]);

  useEffect(() => {
    onFixesFiltered?.(filteredFixes);
  }, [filteredFixes, onFixesFiltered]);

  useEffect(() => {
    selectedItemRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [selectedFix]);

  return (
    <div className='flex flex-col h-full'>
      <FixesListHeader
        fixes={fixes}
        activeFilter={activeFilter}
        onFilterChange={setActiveFilter}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedAgent={selectedAgent}
        setSelectedAgent={setSelectedAgent}
      />

      <div className='overflow-y-auto mt-4 pr-4' style={{ height: "calc(100% - 60px)" }}>
        {filteredFixes.map((fix) => (
          <FixesListCard
            key={fix.id}
            fix={fix}
            isSelected={selectedFix?.id === fix.id}
            onClick={() => onFixClick(fix)}
            elementRef={selectedFix?.id === fix.id ? selectedItemRef : null}
          />
        ))}
        <div className='h-40' />
      </div>
    </div>
  );
}

// Helper component for tag suggestions
const TagSuggestions = ({ tags, searchTerm, onSelect }) => {
  const matchingTags = tags.filter((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()));

  if (!searchTerm && !matchingTags.length) return null;

  return (
    <div className='absolute z-20 w-full mt-[-1rem] bg-white border-x border-b border-gray-300 rounded-b-xl shadow-lg'>
      {matchingTags.map((tag) => (
        <div
          key={tag}
          className='px-6 py-3 hover:bg-gray-50 cursor-pointer text-gray-700 text-[15px]'
          onClick={() => onSelect(tag)}>
          {tag}
        </div>
      ))}
    </div>
  );
};

export default FixesList;
