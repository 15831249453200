import { agent_properties } from "../../../constants/agentConstants";

const FixAISummary = ({ fix }) => {
  const agent = fix.ai_agent ? agent_properties[fix.ai_agent] || {} : {};
  const AgentIcon = agent.icon || (() => null);

  return (
    <div
      className='bg-blue-50 p-4 rounded-lg mb-4 flex items-center'
      style={{ backgroundColor: agent.secondaryColor || "inherit" }}>
      <div
        className='p-2 rounded-md text-white mr-4'
        style={{ backgroundColor: agent.color || "inherit" }}>
        <AgentIcon className='w-5 h-5' />
      </div>
      <p className='text-ai-blue flex-1 font-light'>
        {fix.ai_summary || "No AI summary available."}
      </p>
    </div>
  );
};

export default FixAISummary;
