import { apiSlice } from "./apiSlice";
import { logOut, setCredentials } from "../authSlice";
import { clearSelectedWebsite } from "../selectedWebsiteSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/api/v1/signin",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    signup: builder.mutation({
      query: (credentials) => ({
        url: "/api/v1/signup",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    googleSignup: builder.mutation({
      query: () => ({
        url: "/api/v1/google",
        method: "GET",
      }),
    }),
    sendLogout: builder.mutation({
      query: () => ({
        url: "/api/v1/signout",
        method: "POST",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(logOut());
          dispatch(clearSelectedWebsite());
          setTimeout(() => {
            dispatch(apiSlice.util.resetApiState());
          }, 1500);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    refresh: builder.mutation({
      query: () => ({
        url: "/api/v1/refresh",
        method: "GET",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCredentials({ accessToken: data.access_token }));
        } catch (err) {
          console.error(err);
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useSignupMutation,
  useGoogleSignupMutation,
  useSendLogoutMutation,
  useRefreshMutation,
} = authApiSlice;
