import React from "react";
import { Link } from "react-router-dom";
import StoreHealthScoreVisual from "./StoreHealthScore";
import BatchIssueButton from "../../components/functionality/BatchIssueButton";
import GenericButton from "../../components/functionality/GenericButton";
import RevenuePotential from "../../components/functionality/RevenuePotential";

function StoreHealth({ website, issueCount }) {
  const { overall_score: overallScore, title: brandName } = website || {};

  const textStyle = "text-[rgba(66,72,93,0.60)] text-[14.5px] font-normal leading-5 tracking-[-0.07px]";

  return (
    <div className='rounded-lg mb-8 p-6 px-8'>
      <div className='flex justify-between items-start mb-6 gap-4'>
        <StoreHealthScoreVisual score={overallScore} storeName={brandName} className='w-[120px] flex-shrink-0' />
        <div className='flex-grow flex-shrink-0 max-w-[850px] min-w-0 self-end'>
          <h2 className='text-2xl font-normal text-gray-800'>Store Health</h2>
          <p className={`${textStyle} mt-2`}>
            Your store's health score is based on key areas like UX, Content, SEO, and Performance.
            <br />
            Each area is rated from 1 to 10, and the overall score reflects your store's optimization level.
          </p>
          <div className='flex justify-start space-x-4 mt-6'>
            <Link to='/fixes'>
              <BatchIssueButton issueNumber={issueCount} />
            </Link>
            <Link to='/integrations'>
              <GenericButton innerText={"Integrations"} />
            </Link>
          </div>
        </div>
        <div className='flex-shrink-0 basis-[324px] min-w-0 flex flex-col justify-center items-center pt-10'>
          <RevenuePotential value={0} small={false} />
          <h3 className='text-lg font-semibold text-gray-800 mt-4 text-center'>Total Estimated Impact</h3>
          <p className={`${textStyle} mt-2 text-center`}>Based on similar customer outcomes</p>
        </div>
      </div>
    </div>
  );
}

export default StoreHealth;
