import React, { useState, useRef, useEffect } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedWebsite, selectCurrentWebsiteId } from "../../app/selectedWebsiteSlice";
import useAuth from "../../hooks/useAuth";
import { useGetWebsitesFromUserQuery } from "../../app/api/websiteApiSlice";
function BrandSelector() {
  const dispatch = useDispatch();

  // Get the selected website ID and websites from the Redux store
  const selectedWebsiteId = useSelector(selectCurrentWebsiteId);
  const { id: userId } = useAuth();
  const { websites, selectedWebsite } = useGetWebsitesFromUserQuery(
    { user_id: userId },
    {
      skip: !userId,
      selectFromResult: ({ data }) => ({
        websites: data?.entities ? Object.values(data.entities) : [],
        selectedWebsite: data?.entities?.[selectedWebsiteId] ?? null,
      }),
    }
  );

  // State to manage the open/close state of the selector
  const [isOpen, setIsOpen] = useState(false);
  const selectorRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectorRef]);

  const toggleSelector = () => {
    setIsOpen(!isOpen);
  };

  if (!websites?.length || !selectedWebsite) return null;

  return (
    <div ref={selectorRef} className='relative'>
      <div
        onClick={toggleSelector}
        className='flex items-center space-x-2 cursor-pointer bg-white border  rounded-xl px-3 py-2'>
        {selectedWebsite?.logo_url && (
          <div className='w-5 h-5 bg-gray-100 rounded-full flex items-center justify-center'>
            <img src={selectedWebsite.logo_url} alt={selectedWebsite.title} className='w-4 h-4 object-contain' />
          </div>
        )}
        <span className='font-medium text-gray-700'>{selectedWebsite.title}</span>
        <FiChevronDown className='text-gray-500' size={16} />
      </div>
      {isOpen && (
        <div className='absolute top-full left-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg py-1 z-50'>
          {websites.map((website) => (
            <button
              key={website.id}
              onClick={() => {
                dispatch(setSelectedWebsite(website.id));
                setIsOpen(false);
              }}
              className='w-full flex items-center space-x-2 px-4 py-2 text-left hover:bg-gray-100 focus:outline-none'>
              {website?.logo_url && (
                <div className='w-5 h-5 bg-gray-100 rounded-full flex items-center justify-center'>
                  <img src={website.logo_url} alt={website.title} className='w-4 h-4 object-contain' />
                </div>
              )}
              <span className='font-medium text-gray-700'>{website.title}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default BrandSelector;
