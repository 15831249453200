import React from "react";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { ReactComponent as KeyLeftIcon } from "../../../assets/icons/navigation/keyLeft.svg";
import { ReactComponent as KeyRightIcon } from "../../../assets/icons/navigation/keyRight.svg";
import { useEffect } from "react";

import Fix from "../components/Fix";

function FixDetails({ selectedFix, onPreviousFix, onNextFix, currentFixIndex, totalFixes, stats }) {
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.target.tagName === "INPUT" || event.target.tagName === "TEXTAREA") {
        return;
      }

      if (event.key === "ArrowLeft" && currentFixIndex > 0) {
        onPreviousFix();
      } else if (event.key === "ArrowRight" && currentFixIndex < totalFixes - 1) {
        onNextFix();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentFixIndex, totalFixes, onPreviousFix, onNextFix]);

  if (!selectedFix) {
    return (
      <div className='bg-gray-100 border border-gray-200 rounded-lg pt-40 flex items-start justify-center h-full text-lg'>
        <p className='text-gray-500'>Select a fix to view details</p>
      </div>
    );
  }

  return (
    <>
      <div className='px-6 py-3 flex items-center space-x-4'>
        <div>
          <span className='text-gray-700 font-light'>Fixes: </span>
          <span className='text-custom-gray font-light'>{stats.count} approved</span>
        </div>
      </div>
      <div className='flex flex-col h-full z-0 pl-4'>
        <div className='flex flex-col gap-4'>
          <Fix fix={selectedFix} />
        </div>
        <div className='mt-6 flex justify-between items-center'>
          <button
            className='flex justify-center items-center gap-0.5 px-2.5 py-1.5 rounded-lg  text-gray-600 hover:text-gray-800 transition-colors duration-200'
            onClick={() => navigate("/dashboard")}>
            <FiChevronLeft className='w-5 h-5' /> Back to overview
          </button>
          <div className='flex items-center space-x-6'>
            <div className='flex items-center'>
              <span className='text-[rgba(44,51,71,0.40)] text-center font-poppins text-[14px] font-normal leading-[14px] mr-2'>
                Navigation:
              </span>
              <KeyLeftIcon className='w-8 h-8 mr-1' />
              <KeyRightIcon className='w-8 h-8 mr-1' />
            </div>
            <button
              onClick={onPreviousFix}
              disabled={currentFixIndex === 0}
              className='text-[#42485D99] disabled:text-[#171D3040] transition-colors duration-200'>
              <FaArrowLeft className='w-5 h-5 scale-x-[0.8] scale-y-[0.8]' />
            </button>
            <span className='text-sm font-medium text-[#2C334766]'>
              {currentFixIndex + 1} of {totalFixes}
            </span>
            <button
              onClick={onNextFix}
              disabled={currentFixIndex === totalFixes - 1}
              className='text-[#42485D99] disabled:text-[#171D3040] transition-colors duration-200'>
              <FaArrowRight className='w-5 h-5 scale-x-[0.8] scale-y-[0.8]' />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default FixDetails;
