import React from "react";
import { useSelector } from "react-redux";
import StoreHealth from "../features/overview/StoreHealth";
import HealthCards from "../features/overview/HealthCards";
import OverviewFixes from "../features/overview/OverviewFixes";
import { useGetIssuesQuery } from "../app/api/issueApiSlice";
import { selectCurrentWebsiteId } from "../app/selectedWebsiteSlice";
import { useGetWebsitesFromUserQuery } from "../app/api/websiteApiSlice";
import useAuth from "../hooks/useAuth";

function OverviewPage() {
  const selectedWebsiteId = useSelector(selectCurrentWebsiteId);

  const { id: userId } = useAuth();
  const { selectedWebsite } = useGetWebsitesFromUserQuery(
    { user_id: userId },
    {
      skip: !userId,
      selectFromResult: ({ data }) => ({
        selectedWebsite: data?.entities?.[selectedWebsiteId] ?? null,
      }),
    }
  );

  const { websiteIssues } = useGetIssuesQuery(
    { website_id: selectedWebsiteId },
    {
      skip: !selectedWebsiteId,
      selectFromResult: ({ data }) => ({
        websiteIssues: Object.values(data?.entities ?? {}),
      }),
    }
  );

  if (selectedWebsiteId && selectedWebsite) {
    let issueCount = 0;
    for (const metric in selectedWebsite.ai_agent_issue_counts) {
      issueCount += selectedWebsite.ai_agent_issue_counts[metric];
    }

    return (
      <div className='mx-auto px-2 py-4'>
        <StoreHealth website={selectedWebsite} issueCount={issueCount} />
        <HealthCards website={selectedWebsite} />
        <OverviewFixes fixes={websiteIssues} issueCount={issueCount} />
      </div>
    );
  }

  return <div>No website selected</div>;
}

export default OverviewPage;
