import { googleLogout } from "@react-oauth/google";
import { useSendLogoutMutation } from "../../app/api/authApiSlice";
import { FiLogOut } from "react-icons/fi";

function Logout() {
  const [sendLogout, { isSuccess }] = useSendLogoutMutation();

  const onSuccess = () => {
    console.info("Logout made successfully");
  };

  const handleLogout = async () => {
    try {
      googleLogout();
      const result = await sendLogout().unwrap();
      onSuccess();
      window.location.href = "/login";
    } catch (error) {
      console.error("Logout failed:", error);
      window.location.href = "/login";
    }
  };

  return (
    <button
      onClick={handleLogout}
      className='flex items-center w-full text-left text-sm text-red-600 hover:bg-gray-100'>
      <FiLogOut className='mr-3' size={18} /> Sign out
    </button>
  );
}

export default Logout;
