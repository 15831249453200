import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";
import { setSelectedWebsite } from "../selectedWebsiteSlice";

const websitesAdapter = createEntityAdapter({
  selectId: (website) => website.id,
  // Add sortComparer
  // sortComparer: (a, b) => b.created_at.localeCompare(a.created_at)
});

export const websiteApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET all websites
    getWebsites: builder.query({
      query: ({ skip = 0, limit = 100 } = {}) => ({
        url: "/api/v1/websites/",
        params: { skip, limit },
      }),
      transformResponse: (responseData) => websitesAdapter.setAll(websitesAdapter.getInitialState(), responseData),
      providesTags: (result) => [
        { type: "Website", id: "LIST" },
        ...(result?.ids ?? []).map((id) => ({ type: "Website", id })),
      ],
    }),

    // GET single website
    getWebsite: builder.query({
      query: (websiteId) => `/api/v1/websites/${websiteId}`,
      transformResponse: (website) => websitesAdapter.upsertOne(websitesAdapter.getInitialState(), website),
      providesTags: (result, error, websiteId) => [{ type: "Website", id: websiteId }],
    }),

    // GET websites by user
    getWebsitesFromUser: builder.query({
      query: ({ user_id, skip = 0, limit = 100 }) => ({
        url: `/api/v1/websites/user/${user_id}`,
        params: { skip, limit },
      }),
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const currentSelectedId = getState().selectedWebsite.selectedWebsiteId;
          if (data.ids.length > 0 && !currentSelectedId) {
            dispatch(setSelectedWebsite(data.ids[0]));
          }
        } catch (err) {
          console.error(err);
        }
      },
      transformResponse: (responseData) => websitesAdapter.setAll(websitesAdapter.getInitialState(), responseData),
      providesTags: (result) => [
        { type: "Website", id: "LIST" },
        ...(result?.ids ?? []).map((id) => ({ type: "Website", id })),
      ],
    }),

    // POST create website
    createWebsite: builder.mutation({
      query: (websiteData) => ({
        url: "/api/v1/websites/",
        method: "POST",
        body: websiteData,
      }),
      transformResponse: (website) => websitesAdapter.addOne(websitesAdapter.getInitialState(), website),
      invalidatesTags: [{ type: "Website", id: "LIST" }],
    }),

    // POST add issue
    addIssueId: builder.mutation({
      query: ({ websiteId, issueId }) => ({
        url: `/api/v1/websites/${websiteId}/issues`,
        method: "POST",
        body: { issue_id: issueId },
      }),
      transformResponse: (website) => websitesAdapter.upsertOne(websitesAdapter.getInitialState(), website),
      invalidatesTags: (result, error, { websiteId }) => [
        { type: "Website", id: websiteId },
        { type: "Website", id: "LIST" },
      ],
    }),

    // PUT update website
    updateWebsite: builder.mutation({
      query: ({ websiteId, ...updateData }) => ({
        url: `/api/v1/websites/${websiteId}`,
        method: "PUT",
        body: updateData,
      }),
      transformResponse: (website) => websitesAdapter.upsertOne(websitesAdapter.getInitialState(), website),
      invalidatesTags: (result, error, { websiteId }) => [
        { type: "Website", id: websiteId },
        { type: "Website", id: "LIST" },
      ],
    }),

    // PATCH update health score
    updateHealthScoreDataId: builder.mutation({
      query: ({ websiteId, healthScoreDataId }) => ({
        url: `/api/v1/websites/${websiteId}/health-score`,
        method: "PATCH",
        params: { health_score_data_id: healthScoreDataId },
      }),
      transformResponse: (website) => websitesAdapter.upsertOne(websitesAdapter.getInitialState(), website),
      invalidatesTags: (result, error, { websiteId }) => [
        { type: "Website", id: websiteId },
        { type: "Website", id: "LIST" },
      ],
    }),

    // DELETE website
    deleteWebsite: builder.mutation({
      query: (websiteId) => ({
        url: `/api/v1/websites/${websiteId}`,
        method: "DELETE",
      }),
      transformResponse: (website) => websitesAdapter.removeOne(websitesAdapter.getInitialState(), website.id),
      invalidatesTags: (result, error, websiteId) => [
        { type: "Website", id: websiteId },
        { type: "Website", id: "LIST" },
      ],
    }),

    // DELETE remove issue
    removeIssueId: builder.mutation({
      query: ({ websiteId, issueId }) => ({
        url: `/api/v1/websites/${websiteId}/issues`,
        method: "DELETE",
        body: { issue_id: issueId },
      }),
      transformResponse: (website) => websitesAdapter.upsertOne(websitesAdapter.getInitialState(), website),
      invalidatesTags: (result, error, { websiteId }) => [
        { type: "Website", id: websiteId },
        { type: "Website", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useCreateWebsiteMutation,
  useGetWebsiteQuery,
  useGetWebsitesFromUserQuery,
  useUpdateWebsiteMutation,
  useDeleteWebsiteMutation,
  useUpdateHealthScoreDataIdMutation,
  useAddIssueIdMutation,
  useRemoveIssueIdMutation,
} = websiteApiSlice;

export const selectWebsiteById = createSelector(
  [(state) => state.api.queries, (state, websiteId) => websiteId],
  (queries, websiteId) => {
    const websitesQuery = Object.values(queries).find(
      (query) => query?.data?.entities && query.data.entities[websiteId]
    );

    return websitesQuery?.data?.entities[websiteId] || null;
  }
);
