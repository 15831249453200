import React from "react";
import HighTriangleIcon from "../../assets/icons/metrics/high-triangle-red.svg";
import MediumRoundIcon from "../../assets/icons/metrics/medium-round-orange.svg";
import LowRoundIcon from "../../assets/icons/metrics/low-traingle-gray.svg";

function SeverityIndicator({ severity }) {
  const getSeverityIcon = () => {
    switch (severity?.toLowerCase()) {
      case "high":
        return <img src={HighTriangleIcon} alt='High severity' className='w-3 h-3' />;
      case "medium":
        return <img src={MediumRoundIcon} alt='Medium severity' className='w-3 h-3' />;
      case "low":
        return <img src={LowRoundIcon} alt='Low severity' className='w-3 h-3' />;
      default:
        return null;
    }
  };

  const getBgColor = () => {
    switch (severity?.toLowerCase()) {
      case "high":
        return "bg-[#FEF3F3]";
      case "medium":
        return "bg-[#FFF7F4]";
      case "low":
        return "bg-[#F3F3F7]";
      default:
        return "bg-severity-secondary";
    }
  };

  return (
    <div
      className={`${getBgColor()} text-[#171D30] rounded-full px-2.5 py-0.5 text-sm font-normal flex items-center gap-2`}>
      {getSeverityIcon()}
      {severity?.charAt(0).toUpperCase() + severity?.slice(1)}
    </div>
  );
}

export default SeverityIndicator;
