import { useState, useEffect, useRef } from "react";
import { ReactComponent as GoodIcon } from "../../assets/icons/issues/good.svg";
import { ReactComponent as BadIcon } from "../../assets/icons/issues/bad.svg";
import { useUpdateIssueFeedbackMutation, useUpdateIssueStatusMutation } from "../../app/api/issueApiSlice";

const FeedbackButton = ({ fix }) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedback, setFeedback] = useState("");
  const feedbackRef = useRef(null);
  const [updateFeedback] = useUpdateIssueFeedbackMutation();
  const [updateIssueStatus] = useUpdateIssueStatusMutation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (feedbackRef.current && !feedbackRef.current.contains(event.target)) {
        setShowFeedback(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleGoodClick = async () => {
    await updateFeedback({
      issueId: fix.id,
      feedback_thumbsup: true,
    });
  };

  const handlePoorClick = () => {
    setShowFeedback(true);
  };

  const handleSubmit = async (andDismiss = false) => {
    await updateFeedback({
      issueId: fix.id,
      feedback_thumbsup: false,
      feedback_message: feedback ? feedback : "None",
    });

    if (andDismiss) {
      await updateIssueStatus({
        issueId: fix.id,
        status: "discarded",
      });
    }
    setShowFeedback(false);
    setFeedback("");
  };

  const isThumbsUp = fix.feedback_thumbsup === true;
  const isThumbsDown = fix.feedback_thumbsup === false;

  return (
    <div className='relative min-w-[210px]'>
      <div className='flex'>
        <button
          onClick={handleGoodClick}
          className={`w-fit h-[40px] rounded-l-xl flex items-center text-xs font-medium border border-[#E9E9F0] 
            ${
              isThumbsUp ? "bg-green-100 text-green-700 hover:bg-green-100" : "bg-white text-[#171D30] hover:bg-gray-50"
            }`}>
          <span className='px-3 h-full flex items-center rounded-l-xl'>
            <span className='flex items-center gap-2'>
              <GoodIcon className={`w-4 h-4 ${isThumbsUp ? "text-green-700" : ""}`} />
              <span>Helpful</span>
            </span>
          </span>
        </button>

        <button
          onClick={handlePoorClick}
          className={`w-fit h-[40px] rounded-r-xl flex items-center text-xs font-medium border border-[#E9E9F0] border-l-0
            ${isThumbsDown ? "bg-red-100 text-red-700 hover:bg-red-100" : "bg-white text-[#171D30] hover:bg-gray-50"}`}>
          <span className='px-3 h-full flex items-center rounded-r-xl'>
            <span className='flex items-center gap-2'>
              <BadIcon className={`w-4 h-4 ${isThumbsDown ? "text-red-700" : ""}`} />
              <span>Not Helpful</span>
            </span>
          </span>
        </button>
      </div>

      {showFeedback && (
        <div
          ref={feedbackRef}
          className='absolute right-0 mt-2 w-72 bg-white rounded-lg shadow-lg border border-gray-200 p-4 z-50'>
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder='What could be improved?'
            className='w-full h-24 p-2 text-sm border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none'
          />
          <div className='flex gap-2 mt-3'>
            <button
              onClick={() => handleSubmit(false)}
              className='flex-1 h-[40px] rounded-xl flex items-center justify-center text-xs font-medium bg-[#171D30] text-white hover:bg-[#272D40]'>
              Report feedback
            </button>
            <button
              onClick={() => handleSubmit(true)}
              className='flex-1 h-[40px] rounded-xl flex items-center justify-center text-xs font-medium bg-red-400 text-white hover:bg-red-600 transition-colors'>
              Report & dismiss
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackButton;
