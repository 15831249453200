import React from "react";
import { FiCheck } from "react-icons/fi";

// Import logos
import McGillLogo from "../assets/login/Mcgill.png";
import CaudalieLogo from "../assets/login/Caudalie.png";
import ThatnotabagLogo from "../assets/login/Thatsnotabag.png";
import CircleKLogo from "../assets/login/Circle-K.png";
import PromptifyLogo from "../assets/login/Promptify.png";
import SolignyLogo from "../assets/login/Soligny.png";

const plans = [
  {
    name: "Growth",
    price: 99,
    features: [
      "All Free features",
      "Up to 3 users",
      "Advanced site audit",
      "20 alerts/month",
      "3 connectors",
    ],
  },
  {
    name: "Pro",
    price: 199,
    popular: true,
    features: [
      "All Growth Features",
      "Up to 20 useres",
      "Unlimited alerts",
      "Unlimited connectors",
    ],
  },
  {
    name: "Enterprise",
    features: ["All Pro Features", "Unlimited Users", "Custom and complex integrations"],
  },
];

const clients = [
  { name: "CAUDALIE", logo: CaudalieLogo },
  { name: "SOLIGNY", logo: SolignyLogo },
  { name: "thatsnotabag", logo: ThatnotabagLogo },
  { name: "McGill", logo: McGillLogo },
  { name: "CIRCLE K", logo: CircleKLogo },
  { name: "Promptify", logo: PromptifyLogo },
];

function UpgradePlanPage() {
  return (
    <div className='container mx-auto px-4 py-8'>
      <div className='text-center mb-12'>
        <h1 className='text-4xl font-bold mb-4'>AI9 is more powerful when connected.</h1>
        <div className='mt-8'>
          <h2 className='text-xl font-semibold mb-4'>Our Clients</h2>
          <div className='flex justify-center items-center space-x-8 flex-wrap'>
            {clients.map((client, index) => (
              <div key={index} className='w-24 h-12 flex items-center justify-center'>
                <img
                  src={client.logo}
                  alt={`${client.name} logo`}
                  className='max-w-full max-h-full object-contain'
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`bg-white rounded-lg shadow-md p-6 ${
              plan.popular ? "ring-2 ring-purple-500" : ""
            }`}>
            {plan.popular && (
              <div className='text-purple-600 text-sm font-semibold mb-2'>Most popular</div>
            )}
            <h2 className='text-2xl font-bold mb-4'>{plan.name}</h2>
            <p className='text-gray-600 mb-4'>
              {plan.name === "Growth"
                ? "Grow your store with more insights, more automation, and more tools. Ideal for stores scaling their operations."
                : plan.name === "Pro"
                ? "For fast-growing stores ready to automate even more and get full-stack insights into their marketing, performance, and customer experience."
                : "A fully customizable plan built for large businesses with high-traffic stores and complex needs."}
            </p>
            {plan.price ? (
              <div className='text-3xl font-bold mb-6'>
                ${plan.price}{" "}
                <span className='text-sm font-normal text-gray-500'>/ per month</span>
              </div>
            ) : (
              <div className='text-3xl font-bold mb-6'>Talk to us.</div>
            )}
            <ul className='space-y-2 mb-6'>
              {plan.features.map((feature, featureIndex) => (
                <li key={featureIndex} className='flex items-center'>
                  <FiCheck className='text-green-500 mr-2' />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
            <button
              className={`w-full py-2 px-4 rounded-full ${
                plan.popular ? "bg-purple-600 text-white" : "bg-gray-200 text-gray-800"
              }`}>
              {plan.name === "Enterprise" ? "Select" : "Selected"}
            </button>
          </div>
        ))}
      </div>

      <div className='mt-12 text-center'>
        <div className='flex justify-center mb-4'>
          {[...Array(5)].map((_, i) => (
            <svg
              key={i}
              className='w-5 h-5 text-yellow-400'
              fill='currentColor'
              viewBox='0 0 20 20'>
              <path d='M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z' />
            </svg>
          ))}
        </div>
        <p className='text-xl font-semibold mb-2'>
          "I couldn't believe how much lost revenue I recovered within the first day of using
          AI9"
        </p>
        <p className='text-gray-600'>Mark Markovitch</p>
        <p className='text-gray-500 text-sm'>E-Commerce Manager, Caudalie</p>
      </div>
    </div>
  );
}

export default UpgradePlanPage;
