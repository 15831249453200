import { ReactComponent as IntegrationsIcon } from "../../../assets/icons/header/integrations-line.svg";

const FixFooter = () => {
  return (
    <div className='flex gap-4 items-center pt-4 '>
      <button className='flex items-center gap-2 px-4 py-2 rounded-xl border bg-gray-100 text-gray-400'>
        <IntegrationsIcon className='w-4 h-4' />
        <span>Setup integration</span>
      </button>

      <span className='text-gray-400'>to one click resolution</span>

      <button className='ml-auto px-4 py-2 rounded-xl bg-gray-100 text-gray-400'>Publish with AI</button>
    </div>
  );
};

export default FixFooter;
