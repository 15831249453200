import React from "react";
import { FiSettings } from "react-icons/fi";

// Import logos
import aiLogo from "../assets/icons/ai/integrations-image.svg";
import shopifyLogo from "../assets/icons/third-party/shopify-logo.svg";
import googleSearchConsoleLogo from "../assets/icons/third-party/google-search-console.png";
import klaviyoLogo from "../assets/icons/third-party/klaviyo-logo.png";
import yotpoLogo from "../assets/icons/third-party/yotpo-logo.svg";
import trustpilotLogo from "../assets/icons/third-party/trustpilot.svg";
import bazaarVoiceLogo from "../assets/icons/third-party/bazaarvoice.svg";
import metaLogo from "../assets/icons/third-party/meta-logo.svg";
import salesforceLogo from "../assets/icons/third-party/salesforce-logo.svg";
import connectIcon from "../assets/icons/header/integrations-line.svg";

function IntegrationCard({ logo, name, description, isConnected }) {
  return (
    <div className='bg-white rounded-lg shadow-md p-6 flex flex-col'>
      <div className='flex items-center mb-4'>
        <img src={logo} alt={`${name} logo`} className='w-10 h-10 mr-4' />
        <h3 className='font-light'>{name}</h3>
      </div>
      <p className='text-sm font-light text-gray-400 mb-4 flex-grow'>{description}</p>
      {isConnected ? (
        <div className='flex items-center'>
          <span className='w-2 h-2 bg-green-500 rounded-full mr-2'></span>
          <span className='text-md font-light text-gray-700'>Connected</span>
          <FiSettings className='ml-auto text-gray-400' />
        </div>
      ) : (
        <a
          href='https://calendly.com/arthur-pentecoste/30-min-chat'
          target='_blank'
          rel='noopener noreferrer'
          className='w-full flex items-center justify-center text-md font-light border border-[#E9E9F0] rounded-lg px-3 py-2 transition-colors'>
          <img src={connectIcon} alt='' className='w-4 h-4 mr-2' />
          Connect
        </a>
      )}
    </div>
  );
}

function IntegrationsComingSoon() {
  return (
    <div className='w-full px-4 py-8'>
      <div className='flex flex-col md:flex-row items-center mb-12'>
        <div className='mb-6 md:mb-0 md:mr-12'>
          <img src={aiLogo} alt='AI Integration' className='w-96 h-96' />
        </div>
        <div className='text-center md:text-left'>
          <h1 className='text-4xl font-regular text-gray-800 mb-4'>Integrations</h1>
          <p className='text-gray-400 w-full'>
            Focal Studio works best when your tools work together. <br /> Connect your apps to discover more
            opportunities and speed up fixes that drive results.
          </p>
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6'>
        <IntegrationCard
          logo={shopifyLogo}
          name='Shopify'
          description='Connect your Shopify store to streamline operations and unlock powerful e-commerce insights'
          isConnected={false}
        />
        <IntegrationCard
          logo={googleSearchConsoleLogo}
          name='Google Search Console'
          description='Link your Google Search Console to improve SEO performance and track search metrics'
          isConnected={false}
        />
        <IntegrationCard
          logo={klaviyoLogo}
          name='Klaviyo'
          description='Integrate Klaviyo to supercharge your email marketing automation and customer segmentation'
          isConnected={false}
        />
        <IntegrationCard
          logo={yotpoLogo}
          name='Yotpo'
          description='Connect Yotpo to leverage customer reviews and boost social proof on your store'
          isConnected={false}
        />
        <IntegrationCard
          logo={trustpilotLogo}
          name='Trustpilot'
          description='Sync your Trustpilot reviews to showcase customer feedback and build trust'
          isConnected={false}
        />
        <IntegrationCard
          logo={bazaarVoiceLogo}
          name='Bazaarvoice'
          description='Link Bazaarvoice to amplify your product ratings and user-generated content'
          isConnected={false}
        />
        <IntegrationCard
          logo={metaLogo}
          name='Meta Ads'
          description='Connect Meta Ads to optimize your social media advertising and track ROI'
          isConnected={false}
        />
        <IntegrationCard
          logo={salesforceLogo}
          name='Salesforce'
          description='Integrate Salesforce to enhance customer relationship management and sales tracking'
          isConnected={false}
        />
      </div>
    </div>
  );
}

export default IntegrationsComingSoon;
