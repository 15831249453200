import React from "react";

const Block = ({ children, hasStack = false, stackOnTop = true, className = "" }) => {
  if (!hasStack) {
    return (
      <div className={`relative bg-white rounded-lg shadow-subtle p-6 ${className}`}>
        {children}
      </div>
    );
  }

  const stackStyles = stackOnTop
    ? {
        topStack: "top-[-30px] translate-y-0 h-[calc(100%+20px)]",
        bottomStack: "top-[-15px] translate-y-0 h-[calc(100%+10px)]",
        margin: "mt-[30px]",
        transition: "",
      }
    : {
        topStack: "bottom-[-7px] translate-y-2 h-[calc(100%+5px)]",
        bottomStack: "bottom-[-3.5px] translate-y-1 h-[calc(100%+2.5px)]",
        margin: "mb-[5px]",
        transition: "transition-transform duration-300 group-hover:translate-y-[-3px]",
      };

  return (
    <div className={`relative ${stackStyles.margin} ${className} group`}>
      <div
        className={`absolute left-0 right-0 ${stackStyles.topStack} bg-gray-custom-1 bg-opacity-50 rounded-lg transform translate-x-[5%] w-[90%] ${stackStyles.transition}`}></div>
      <div
        className={`absolute left-0 right-0 ${stackStyles.bottomStack} bg-gray-custom-1 rounded-lg transform translate-x-[2.5%] w-[95%] ${stackStyles.transition}`}></div>
      <div className={`relative bg-white rounded-lg shadow-subtle p-6 ${stackOnTop ? "" : "transition-transform duration-300 group-hover:translate-y-[-2px]"}`}>
        {children}
      </div>
    </div>
  );
};

Block.Divider = () => <hr className='border-t border-gray-200 m-0 w-full' />;

export default Block;
