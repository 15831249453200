import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import BatchIssueButton from "../../components/functionality/BatchIssueButton";
import SeverityIndicator from "../../components/functionality/SeverityIndicator";
import { agent_properties } from "../../constants/agentConstants";

function OverviewFixesItem({ upgrade }) {
  const { icon: Icon, color, name } = agent_properties[upgrade.ai_agent];

  const getStatusText = (status) => {
    switch (status) {
      case "done":
        return "Completed";
      case "pending":
        return "In Progress";
      default:
        return "Review & Fix";
    }
  };

  return (
    <Link to={`/fixes/${upgrade.id}`} className='block'>
      <div className='bg-white rounded-lg shadow-sm p-6 flex justify-between items-center hover:bg-gray-50 transition-colors duration-200'>
        <div className='flex items-center space-x-4 flex-grow'>
          <div className='flex flex-col items-start'>
            <div className='flex items-center space-x-2 mb-2'>
              <div className={`p-1 rounded-md text-white`} style={{ backgroundColor: color }}>
                <Icon size={16} />
              </div>
              <span className='text-sm font-medium text-gray-600'>{name}</span>
            </div>
            <h3 className='text-lg font-medium text-gray-800'>{upgrade.title}</h3>
            <p className='text-sm text-gray-500'>{upgrade.shortlink_title}</p>
          </div>
        </div>
        <div className='flex items-center space-x-4'>
          <SeverityIndicator severity={upgrade.severity} />
          <span className='text-[rgba(66,72,93,0.60)] text-center font-poppins text-sm font-medium leading-5 tracking-[-0.07px]'>
            {getStatusText(upgrade.status)}
          </span>
        </div>
      </div>
    </Link>
  );
}

function OverviewFixes({ fixes, issueCount }) {
  const [activeTab, setActiveTab] = useState("all");
  const [underlineStyle, setUnderlineStyle] = useState({});
  const tabRefs = useRef([]);

  const tabs = useMemo(
    () => [
      { id: "all", label: "All fixes" },
      { id: "approval", label: "Ready for Approval" },
      { id: "review", label: "Need review" },
    ],
    []
  );

  useEffect(() => {
    const activeTabIndex = tabs.findIndex((tab) => tab.id === activeTab);
    const activeTabElement = tabRefs.current[activeTabIndex];

    if (activeTabElement) {
      const buttonWidth = activeTabElement.offsetWidth;
      const underlineWidth = buttonWidth * 0.9;
      const underlineLeft = activeTabElement.offsetLeft + (buttonWidth - underlineWidth) / 2;

      setUnderlineStyle({
        left: `${underlineLeft}px`,
        width: `${underlineWidth}px`,
      });
    }
  }, [activeTab, tabs]);

  const filteredFixes = fixes
    .filter((fix) => {
      if (activeTab === "approval") return !fix.needs_review;
      if (activeTab === "review") return fix.needs_review;
      return true;
    })
    .sort((a, b) => {
      const statusOrder = { done: 0, pending: 1, assigned: 2 };
      const aStatus = statusOrder[a.status] ?? statusOrder.undefined;
      const bStatus = statusOrder[b.status] ?? statusOrder.undefined;
      if (aStatus !== bStatus) {
        return bStatus - aStatus;
      }

      const severityOrder = { high: 0, medium: 1, low: 2 };
      if (a.severity !== b.severity) {
        return severityOrder[a.severity] - severityOrder[b.severity];
      }

      return a.shortlink_url.localeCompare(b.shortlink_url);
    });

  return (
    <div className='container mx-auto max-w-1xl px-4'>
      <div className='space-y-6'>
        <div className='flex justify-between items-center'>
          <h2 className='text-2xl font-semibold text-gray-800'>Fixes</h2>
          <Link to='/fixes'>
            <BatchIssueButton issueNumber={issueCount} dark={true} />
          </Link>
        </div>
        <div className='flex justify-start mb-6 relative'>
          {tabs.map((tab, index) => (
            <button
              key={tab.id}
              ref={(el) => (tabRefs.current[index] = el)}
              className={`text-gray-800 font-medium pb-2 relative px-2 ${
                activeTab === tab.id ? "text-gray-800" : "text-gray-500"
              }`}
              onClick={() => setActiveTab(tab.id)}>
              {tab.label}
            </button>
          ))}
          <div
            className='absolute bottom-0 h-0.5 bg-gray-800 transition-all duration-300 ease-in-out'
            style={underlineStyle}
          />
        </div>
        <div className='space-y-4'>
          {filteredFixes.map((upgrade) => (
            <OverviewFixesItem key={upgrade.id} upgrade={upgrade} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default OverviewFixes;
