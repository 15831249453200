import React from "react";

const RowFix = ({ data }) => {
  const renderContent = () => {
    if (data.content.text !== null) {
      return <span className='text-[#171D30] font-medium text-lg'>{data.content.text}</span>;
    }

    return (
      <div className='flex items-center justify-between flex-1 ml-4'>
        <div className='text-[#171D30]'>{data.content.after}</div>
        <div className='text-[#42485D]'>{data.content.before}</div>
      </div>
    );
  };

  return (
    <>
      <div className='flex items-center py-4'>
        <div className='flex items-center gap-2 w-[200px]'>
          <span className='font-medium text-[#42485D]'>{data.title}</span>
          {data.diff && <span className='text-[#42485D]'>-{data.diff}</span>}
        </div>
        {renderContent()}
      </div>
      <hr className='border-[#E9E9F0]' />
    </>
  );
};

export default RowFix;
