import React from 'react';

export const GoogleIcon: React.FC = () => (
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label="Google logo"
  >
    <path
      d="M24 12.276C24 11.4603 23.9325 10.6402 23.7886 9.83774H12.2418V14.4586H18.8541C18.5797 15.9489 17.6981 17.2672 16.4071 18.105V21.1032H20.352C22.6685 19.0133 24 15.9268 24 12.276Z"
      fill="#4285F4"
    />
    <path
      d="M12.2413 24C15.543 24 18.3274 22.9374 20.356 21.1032L16.4111 18.1049C15.3136 18.8369 13.8967 19.2513 12.2458 19.2513C9.05214 19.2513 6.34424 17.1393 5.37263 14.2998H1.30179V17.3906C3.37994 21.4427 7.61272 24 12.2413 24Z"
      fill="#34A853"
    />
    <path
      d="M5.36851 14.2998C4.85572 12.8095 4.85572 11.1957 5.36851 9.70542V6.61458H1.30216C-0.434131 10.0052 -0.434504 14 1.30179 17.3906L5.36851 14.2998Z"
      fill="#FBBC04"
    />
    <path
      d="M12.2413 4.7495C13.9866 4.72304 15.6734 5.36678 16.9374 6.54844L20.4325 3.12251C18.2194 1.08547 15.2821 -0.0344648 12.2413 0.000808638C7.61272 0.000808638 3.38032 2.55813 1.30216 6.61458L5.36851 9.70542C6.33561 6.8615 9.04764 4.7495 12.2413 4.7495Z"
      fill="#EA4335"
    />
  </svg>
);
