import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { MicrosoftIcon } from "../../assets/icons/MicrosoftIcon"; // Assuming you have this
import { setCredentials } from "../../app/authSlice";
import { useEffect } from "react";

const MicrosoftIcon = () => (
  <svg width='20' height='20' viewBox='0 0 21 21' xmlns='http://www.w3.org/2000/svg'>
    <rect x='1' y='1' width='9' height='9' fill='#f25022' />
    <rect x='11' y='1' width='9' height='9' fill='#00a4ef' />
    <rect x='1' y='11' width='9' height='9' fill='#7fba00' />
    <rect x='11' y='11' width='9' height='9' fill='#ffb900' />
  </svg>
);

const MicrosoftLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const API_URL =
    process.env.REACT_APP_NODE_ENV === "development"
      ? "http://localhost:8000/api/v1"
      : process.env.REACT_APP_NODE_ENV === "staging"
      ? "https://staging.api.focalstudio.ai/api/v1"
      : "https://api.focalstudio.ai/api/v1";

  useEffect(() => {
    const access_token = searchParams.get("access_token");

    if (access_token) {
      dispatch(setCredentials({ accessToken: access_token }));
      // Clean up URL
      window.history.replaceState({}, document.title, "/login");
      navigate("/dashboard", { replace: true });
    }
  }, [searchParams, dispatch, navigate]);

  return (
    <a href={`${API_URL}/microsoft`}>
      <button className='w-full flex items-center justify-center gap-1 rounded-xl  px-12 py-3 border shadow-sm text-sm font-medium  bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
        <MicrosoftIcon />
        <span className='ml-2'>Microsoft</span>
      </button>
    </a>
  );
};

export default MicrosoftLogin;
