import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentWebsiteId } from "../app/selectedWebsiteSlice";
import { useGetIssuesQuery } from "../app/api/issueApiSlice";
import FixesList from "../features/fixes/fixesPage/FixesList";
import FixDetails from "../features/fixes/fixesPage/FixDetails";

const FixPage = () => {
  const navigate = useNavigate();
  const { fixId } = useParams();
  const selectedWebsiteId = useSelector(selectCurrentWebsiteId);

  const { websiteIssues: fixes } = useGetIssuesQuery(
    { website_id: selectedWebsiteId },
    {
      skip: !selectedWebsiteId,
      selectFromResult: ({ data }) => ({
        websiteIssues: Object.values(data?.entities ?? {}),
      }),
    }
  );

  const [filteredFixes, setFilteredFixes] = useState([]);
  const [selectedFix, setSelectedFix] = useState(null);

  const currentFixIndex = filteredFixes.findIndex((fix) => fix?.id === selectedFix?.id);

  const stats = useMemo(() => {
    if (!fixes) return { revenue: 0, count: 0 };

    return fixes.reduce(
      (acc, fix) => {
        if (fix.status === "done") {
          return {
            revenue: acc.revenue + (fix.impact_usd || 0),
            count: acc.count + 1,
          };
        }
        return acc;
      },
      { revenue: 0, count: 0 }
    );
  }, [fixes]);

  const handleFixClick = (fix) => {
    setSelectedFix(fix);
    navigate(`/fixes/${fix.id}`);
  };

  const handlePreviousFix = () => {
    if (currentFixIndex > 0) {
      handleFixClick(filteredFixes[currentFixIndex - 1]);
    }
  };

  const handleNextFix = () => {
    if (currentFixIndex < filteredFixes.length - 1) {
      handleFixClick(filteredFixes[currentFixIndex + 1]);
    }
  };

  useEffect(() => {
    if (filteredFixes.length > 0) {
      if (fixId) {
        const fix = filteredFixes.find((f) => f.id === fixId);
        if (fix) {
          setSelectedFix(fix);
        } else {
          setSelectedFix(filteredFixes[0]);
        }
      } else {
        setSelectedFix(filteredFixes[0]);
      }
    } else {
      setSelectedFix(null);
    }
  }, [fixId, filteredFixes, selectedWebsiteId]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [selectedFix]);

  const handleFixesFiltered = useCallback(
    (newFilteredFixes) => {
      if (JSON.stringify(newFilteredFixes) !== JSON.stringify(filteredFixes)) {
        setFilteredFixes(newFilteredFixes);
      }
    },
    [filteredFixes]
  );

  return (
    <div className='flex w-full min-h-[2000px]'>
      <div className='w-[30%] sticky top-[15px] h-screen'>
        <FixesList
          fixes={fixes}
          selectedFix={selectedFix}
          onFixClick={handleFixClick}
          onFixesFiltered={handleFixesFiltered}
        />
      </div>
      <div className='w-[73%]'>
        <FixDetails
          selectedFix={selectedFix}
          currentFixIndex={currentFixIndex}
          totalFixes={filteredFixes.length}
          onPreviousFix={handlePreviousFix}
          onNextFix={handleNextFix}
          stats={stats}
        />
      </div>
    </div>
  );
};

export default FixPage;
