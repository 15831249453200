import React from "react";
import Login from "./Login";
import McGillLogo from "../../assets/login/Mcgill.png";
import CaudalieLogo from "../../assets/login/Caudalie.png";
import ThatnotabagLogo from "../../assets/login/Thatsnotabag.png";
import CircleKLogo from "../../assets/login/Circle-K.png";
import PromptifyLogo from "../../assets/login/Promptify.png";
import SolignyLogo from "../../assets/login/Soligny.png";
import GoogleLogin from "./GoogleLogin";
import MicrosoftLogin from "./MicrosoftLogin";

const LoginPage = () => {
  const brands = [
    { name: "McGill", logo: McGillLogo },
    { name: "Caudalie", logo: CaudalieLogo },
    { name: "Thatsnotabag", logo: ThatnotabagLogo },
    { name: "Circle K", logo: CircleKLogo },
    { name: "Promptify", logo: PromptifyLogo },
    { name: "Soligny", logo: SolignyLogo },
  ];

  return (
    <div className='flex flex-col justify-between min-h-screen bg-[#F7F7F9]'>
      <div className='flex-grow flex items-center justify-center'>
        <div className='w-full max-w-6xl space-y-10 text-center px-4'>
          <h1 className='text-5xl font-normal text-gray-900'>Welcome to Focal Studio</h1>

          <p className='text-base text-gray-600 font-normal mx-auto max-w-lg'>
            You are one step closer to monitoring and optimizing your omnichannel e-commerce content week-over-week.
          </p>

          <div className='space-y-6 mb-10'>
            <p className='text-base text-gray-600 font-normal'>Please sign in to your account</p>
            <div className='flex flex-col items-center space-y-4 max-w-sm mx-auto'>
              <div className='flex gap-3'>
                <GoogleLogin />
                <MicrosoftLogin />
              </div>

              <div className='flex items-center justify-center'>
                <span className='border-b w-1/5 lg:w-1/4'></span>
                <span className='text-xs text-center text-gray-500 uppercase px-2'>or</span>
                <span className='border-b w-1/5 lg:w-1/4'></span>
              </div>
              <Login />
            </div>
          </div>

          <div className='space-y-6 mt-20'>
            <p className='text-sm text-gray-500 font-normal'>Join leading brands already using Focal Studio</p>
            <div className='grid grid-cols-6 gap-x-16'>
              {brands.map((brand) => (
                <div key={brand.name} className='w-full flex items-center justify-center'>
                  <img src={brand.logo} alt={brand.name} className='w-full h-auto object-contain' />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='w-full max-w-xl text-[10px] text-gray-500 font-normal space-y-1 text-center mx-auto mb-8'>
        <p>Your privacy is our priority: We never compromise on data security</p>
        <p>By creating an account, you agree to our Terms & Conditions and Privacy Policy</p>
      </div>
    </div>
  );
};

export default LoginPage;
