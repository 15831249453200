import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import authReducer from "./authSlice";
import selectedWebsiteReducer from "./selectedWebsiteSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    selectedWebsite: selectedWebsiteReducer,
  },
  //! With external API
  // getDefaultMiddleware().concat(apiSlice.middleware, externalApiSlice.middleware),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),

  devTools: process.env.REACT_APP_NODE_ENV === "development" ? true : false,
});
