import { useSelector } from "react-redux";
import { selectCurrentToken } from "../app/authSlice";
import { jwtDecode } from "jwt-decode";

const useAuth = () => {
  const token = useSelector(selectCurrentToken);
  if (token) {
    const decoded = jwtDecode(token);
    const { username, email, id, google_id, is_superuser, authorized_website_ids } = decoded;

    return {
      username,
      email,
      id,
      google_id,
      is_superuser,
      authorized_website_ids,
    };
  }
  return {
    username: "",
    email: "",
    id: "",
    google_id: "",
    is_superuser: false,
    authorized_website_ids: [],
  };
};

export default useAuth;
