import { Outlet } from "react-router-dom";
import { useGetWebsitesFromUserQuery } from "../../app/api/websiteApiSlice";
import { useGetIssuesQuery } from "../../app/api/issueApiSlice";
import useAuth from "../../hooks/useAuth"; // adjust path as needed
import Header from "./Header";
import Footer from "./Footer";
import { selectCurrentWebsiteId } from "../../app/selectedWebsiteSlice";
import { useSelector } from "react-redux";
import { Spinner } from "../functionality/Spinner";

const DashLayout = () => {
  const { id: userId } = useAuth();
  const selectedWebsiteId = useSelector(selectCurrentWebsiteId);
  const { isLoading: websitesLoading, error } = useGetWebsitesFromUserQuery(
    { user_id: userId },
    {
      skip: !userId,
    }
  );

  const { data: issues } = useGetIssuesQuery({ website_id: selectedWebsiteId }, { skip: !selectedWebsiteId });

  return (
    <div className='flex flex-col min-h-screen bg-gray-100'>
      <Header />
      <div className='flex-grow mx-14 py-8 min-h-full'>
        {websitesLoading ? (
          <div className='flex items-center justify-center h-full min-h-[50vh]'>
            <Spinner size='lg' />
          </div>
        ) : error ? (
          <div>Error loading websites</div>
        ) : (
          <Outlet />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default DashLayout;
