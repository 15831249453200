import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Block from "../../components/layout/Block";
import BatchIssueButton from "../../components/functionality/BatchIssueButton";
import { agent_properties } from "../../constants/agentConstants";
import WarningIcon from "../../assets/icons/metrics/Warning.svg";

function CircularProgress({ score, color, secondaryColor, size, issueCount }) {
  const strokeWidth = 4;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = radius * 2 * Math.PI;
  const percentage = score * 10;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <svg height={size} width={size} className='transform -rotate-90'>
      <circle
        stroke={secondaryColor}
        fill='transparent'
        strokeWidth={strokeWidth}
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      {!(issueCount === 0 && score < 10) && (
        <circle
          stroke={color}
          fill='transparent'
          strokeWidth={strokeWidth}
          strokeDasharray={circumference + " " + circumference}
          style={{ strokeDashoffset }}
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
      )}
    </svg>
  );
}

function LoadingText() {
  const [dots, setDots] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev + 1) % 4);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='w-[120px] h-[40px] rounded-xl flex items-center justify-center text-sm font-medium bg-white text-gray-300 border border-gray-200'>
      <span>Scanning</span>
      <span className='w-[18px] inline-block'>{".".repeat(dots)}</span>
    </div>
  );
}

function MetricCard({ metric, score, issueCount }) {
  return (
    <Block hasStack={issueCount > 0} stackOnTop={false}>
      <Link to='/fixes' className='group'>
        <div className='flex justify-between items-center'>
          <div className='flex-1'>
            <div className='flex items-center space-x-3 mb-4'>
              <div
                className={`p-2 rounded-md text-white group-hover:opacity-80 transition-opacity`}
                style={{ backgroundColor: metric.color }}>
                <metric.icon className='w-5 h-5' />
              </div>
              <h3 className='text-[18px] font-medium text-[#171D30] group-hover:text-gray-600 transition-colors font-poppins leading-[26px] tracking-[-0.216px]'>
                {metric.name}
              </h3>
            </div>

            {score === 10 ? (
              <div className='w-[120px] h-[40px] rounded-xl flex items-center justify-center text-sm font-medium bg-white text-gray-300 border border-gray-200'>
                Great job!
              </div>
            ) : score === -1 ? (
              <LoadingText />
            ) : score === -2 ? (
              <div className='w-[120px] h-[40px] rounded-xl flex items-center justify-center text-sm font-medium bg-white text-gray-300 border border-gray-200'>
                Coming soon
              </div>
            ) : issueCount > 0 ? (
              <Link to='/fixes'>
                <BatchIssueButton issueNumber={issueCount} dark={false} />
              </Link>
            ) : (
              <LoadingText />
            )}
          </div>
          <div className='relative flex-shrink-0 ml-4'>
            <CircularProgress
              score={score}
              color={metric.color}
              secondaryColor={metric.secondaryColor}
              size={80}
              issueCount={issueCount}
            />
            {/* Change back to 4 later */}
            {score <= -5 && (
              <img
                src={WarningIcon}
                alt='Warning'
                className='absolute top-0 right-0 w-6 h-6 transform translate-x- -translate-y-1/10'
              />
            )}
            <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center'>
              <span className='text-[#171D30] text-center font-poppins text-2xl sm:text-3xl md:text-4xl font-light leading-8 tracking-[-0.504px]'>
                <span className={issueCount === 0 && score < 10 ? "text-gray-400" : ""}>
                  {issueCount === 0 && score < 10 ? "-" : score}
                </span>
              </span>
              <div className='w-2 h-px bg-gray-300 mx-auto my-0.5'></div>
              <span className='text-[10px] sm:text-xs font-light text-gray-400 flex items-center justify-center'>
                10
              </span>
            </div>
          </div>
        </div>
      </Link>
    </Block>
  );
}

function HealthCards({ website }) {
  const { ai_agent_scores: aiAgentScores, ai_agent_issue_counts: aiAgentIssueCounts } = website || {};

  const metricsWithScores = Object.entries(agent_properties).map(([key, metric]) => ({
    ...metric,
    id: key,
    score: aiAgentScores ? aiAgentScores[key] || 0 : 0,
    issueCount: aiAgentIssueCounts ? aiAgentIssueCounts[key] || 0 : 0,
  }));

  // const sortedMetrics = metricsWithScores.sort((a, b) => b.score - a.score);
  const sortedMetrics = metricsWithScores;

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8'>
      {sortedMetrics.map((metric) => (
        <MetricCard key={metric.id} metric={metric} score={metric.score} issueCount={metric.issueCount} />
      ))}
    </div>
  );
}

export default HealthCards;
