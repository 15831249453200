import React from "react";

const TextFix = ({ data }) => {
  return (
    <div className='space-y-4'>
      <div className='grid grid-cols-2 gap-8'>
        <div className='flex flex-col h-full'>
          <h3 className='text-gray-500 mb-2'>Updated Text:</h3>
          <div className='p-4 bg-gray-50 rounded-lg flex-1'>
            {data.content.after || data.content.text || "No updated text"}
          </div>
        </div>

        <div className='flex flex-col h-full'>
          <h3 className='text-gray-500 mb-2'>Original Text:</h3>
          <div className='p-4 bg-gray-50 rounded-lg flex-1'>{data.content.before || "No original text"}</div>
        </div>
      </div>
    </div>
  );
};

export default TextFix;
