import React from "react";
import { agent_properties } from "../../../constants/agentConstants";
import SeverityIndicator from "../../../components/functionality/SeverityIndicator";
import { ReactComponent as PendingIcon } from "../../../assets/icons/issues/pending.svg";

function FixesListCard({ fix, isSelected, onClick, elementRef }) {
  const agentProperty = agent_properties[fix.ai_agent] || {};
  const AgentIcon = agentProperty.icon || (() => null);

  const severity = fix.severity?.toLowerCase() || "high";

  return (
    <div
      ref={elementRef}
      className={`relative rounded-lg mb-4 cursor-pointer overflow-hidden transition-colors duration-300 ${
        isSelected ? "bg-blue-50" : "bg-white"
      }`}
      onClick={onClick}>
      <div className={`absolute inset-0 border rounded-lg ${isSelected ? "border-blue-200" : "border-gray-200"}`} />
      <div className='p-5'>
        <div className='flex items-center justify-between mb-2.5'>
          <div className='flex items-center space-x-2.5 text-gray-600 text-base'>
            <div className='rounded-md p-1.5' style={{ backgroundColor: agentProperty.color }}>
              <AgentIcon className='w-4 h-4 text-white' />
            </div>
            <span>{`${agentProperty.name || fix.ai_agent} AI`}</span>
          </div>
          {fix?.status === "done" ? (
            <div className='text-white rounded-full p-1.5' style={{ backgroundColor: "#40C6A7" }}>
              <svg
                className='w-3 h-3'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                stroke='currentColor'
                strokeWidth='3'>
                <path d='M5 13l4 4L19 7' strokeLinecap='round' strokeLinejoin='round' />
              </svg>
            </div>
          ) : fix?.status === "pending" ? (
            <div className='text-white rounded-full p-1.5 '>
              <PendingIcon className='w-6 h-6' />
            </div>
          ) : (
            <SeverityIndicator severity={severity} />
          )}
        </div>
        <h3 className='text-lg flex items-center mb-1.5'>
          {fix.title}
          <span className='ml-2 text-purple-500'></span>
        </h3>
        <p className='text-sm text-gray-500 font-light underline'>{fix.shortlink_title}</p>
      </div>
    </div>
  );
}

export default FixesListCard;
