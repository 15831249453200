import React from "react";
import { ReactComponent as OverallScoreSVG } from "../../assets/icons/metrics/overallScore.svg";
import "./healthVisual.css";

function StoreHealthScoreVisual({ score, storeName }) {
  return (
    <div className='health-container'>
      <div className=''>
        <OverallScoreSVG className='' />
      </div>
      <div className='banner-score-container'>
        <CircularProgress score={score} />
        <div className='vertical-lines'>
          <div className='line'></div>
          <div className='line'></div>
        </div>
        <div className='store-name-banner'>{storeName}</div>
      </div>
    </div>
  );
}

function CircularProgress({ score, color = "#FABE3D", size = 58 }) {
  const percentage = score * 10;
  const strokeWidth = 3.5;
  const radius = size / 2 - strokeWidth;
  const circumference = radius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div className='overall-score-circle-wrapper'>
      <div className='overall-score-circle'>
        <svg height={size} width={size} className='transform -rotate-90'>
          <circle
            stroke='#e6e6e6'
            fill='transparent'
            strokeWidth={strokeWidth}
            r={radius}
            cx={size / 2}
            cy={size / 2}
          />
          <circle
            stroke={color}
            fill='transparent'
            strokeWidth={strokeWidth}
            strokeDasharray={circumference + " " + circumference}
            style={{ strokeDashoffset }}
            r={radius}
            cx={size / 2}
            cy={size / 2}
          />
        </svg>
      </div>
      <div className='overall-score-text'>
        <span className='text-xl font-bold'>{score}</span>
      </div>
    </div>
  );
}

export default StoreHealthScoreVisual;
