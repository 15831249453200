import React from "react";

const GenericButton = ({ innerText, onClick, dark = false }) => {
  const baseClasses =
    "flex items-center justify-center gap-1 px-3 py-2.5 text-sm font-medium rounded-xl transition-all duration-150 ease-in-out";
  const darkClasses = "text-white bg-[#171D30] border border-[#E9E9F0] hover:bg-[#1E2438] hover:border-[#1E2438]";
  const lightClasses = "text-[#171D30] bg-white border border-[#E9E9F0] hover:bg-[#FAFAFB] hover:border-[#E1E1E6]";

  const buttonClasses = `${baseClasses} ${
    dark ? darkClasses : lightClasses
  } shadow-[0_2px_4px_rgba(20,21,26,0.08)] hover:shadow-[0_4px_6px_rgba(20,21,26,0.12)] hover:scale-[1.005]`;

  return (
    <button className={buttonClasses} onClick={onClick}>
      {innerText}
    </button>
  );
};

export default GenericButton;
