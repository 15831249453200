import { useState } from "react";
import { FiChevronDown, FiCopy } from "react-icons/fi";
import { ReactComponent as JiraIcon } from "../../../assets/icons/third-party/jira-icon.svg";
// import { MdThumbUp, MdThumbDown } from "react-icons/md";
import IssuesTicketButton from "../../../components/functionality/IssuesTicketButton";

const htmlToText = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");

  const headers = doc.querySelectorAll("h1, h2");
  headers.forEach((header) => {
    header.insertAdjacentText("afterend", "\n\n");
  });

  doc.querySelectorAll("ul > li").forEach((item) => {
    if (!item.closest("ol")) {
      item.insertAdjacentText("afterbegin", "• ");
    }
    item.insertAdjacentText("beforeend", "\n");
  });

  doc.querySelectorAll("ol").forEach((list) => {
    let index = 1;
    list.querySelectorAll(":scope > li").forEach((item) => {
      item.insertAdjacentText("afterbegin", `${index}. `);
      item.insertAdjacentText("beforeend", "\n");
      index++;
    });
  });

  doc.querySelectorAll("ol li ul").forEach((list) => {
    list.querySelectorAll("li").forEach((item) => {
      item.textContent = item.textContent.replace("• ", "");
      item.insertAdjacentText("afterbegin", "    • ");
      item.insertAdjacentText("beforeend", "\n");
    });
    list.insertAdjacentText("afterend", "\n");
  });

  const lists = doc.querySelectorAll("ul, ol");
  lists.forEach((list) => {
    list.insertAdjacentText("afterend", "\n");
  });

  return doc.body.textContent.replace(/\n{3,}/g, "\n\n").trim();
};

const TicketSection = ({ title, content, isExpanded, onToggle }) => {
  const [showCopied, setShowCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(`${title}\n\n${htmlToText(content)}`);
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 1000);
  };

  return (
    <div>
      <div className='flex justify-between items-center'>
        <div onClick={onToggle} className='flex items-center py-4 gap-3 cursor-pointer flex-grow'>
          <FiChevronDown className={`w-5 h-5 transition-transform ${isExpanded ? "transform rotate-180" : ""}`} />
          <span className='font-medium text-lg'>{title}</span>
        </div>
        <div className='relative'>
          <button onClick={handleCopy} className='flex items-center gap-2 px-3 py-1'>
            <FiCopy className='w-5 h-5 text-[#42485D99]' />
          </button>
          {showCopied && (
            <div className='absolute -top-8 right-0 bg-gray-800/90 text-white/90 px-2 py-1 rounded text-xs'>
              Copied!
            </div>
          )}
        </div>
      </div>
      {isExpanded && (
        <div
          className='px-8 
          [&>div>h1]:text-xl [&>div>h1]:font-medium [&>div>h1]:mb-6
          [&>div>h2]:text-base [&>div>h2]:font-medium [&>div>h2]:mb-4 [&>div>h2]:mt-2
          [&>div>ol]:list-decimal [&>div>ol]:ml-6 [&>div>ol]:mb-4
          [&>div>ul]:list-disc [&>div>ul]:ml-6 [&>div>ul]:mb-4
          [&>div>p]:mb-4
          [&_a]:text-blue-600 [&_a]:underline'
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
      <hr className='border-gray-200 -mx-6' />
    </div>
  );
};

const FixResolution = ({ subfix }) => {
  const [showDetails, setShowDetails] = useState(true);
  const [expandedSections, setExpandedSections] = useState(() => {
    const sections = subfix.ticket.ticket_body.split(/<h1>/).slice(1);
    return sections.reduce((acc, _, index) => ({ ...acc, [index]: true }), {});
  });

  const sections = subfix.ticket.ticket_body
    .split(/<h1>/)
    .slice(1)
    .map((section) => {
      const endOfTitle = section.indexOf("</h1>");
      return {
        title: section.substring(0, endOfTitle),
        content: "<div>" + section.substring(endOfTitle + 5) + "</div>",
      };
    });

  const toggleSection = (index) => {
    setExpandedSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const copyTicketToClipboard = () => {
    const textContent = htmlToText(subfix.ticket.ticket_body);
    navigator.clipboard.writeText(textContent).catch((err) => console.error("Failed to copy text: ", err));
  };

  return (
    <div className='bg-white border border-gray-200 rounded-lg p-6'>
      <div className='flex justify-between items-center'>
        <h3 className='text-md text-[#2C334766]'>Or resolve it with your team</h3>

        <div className='flex items-center space-x-6'>
          <div className='flex items-center gap-3'>
            <span className='ml-3 text-sm font-medium text-gray-700'>Details</span>
            <label className='relative inline-flex items-center cursor-pointer'>
              <input
                type='checkbox'
                className='sr-only peer'
                checked={showDetails}
                onChange={() => setShowDetails(!showDetails)}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#171D30]"></div>
            </label>
          </div>

          <IssuesTicketButton issueId={subfix.issue_id} onCopyTicket={copyTicketToClipboard} />
        </div>
      </div>

      {showDetails && (
        <div className='my-10'>
          <div className='prose max-w-none'>
            <div className='space-y-4'>
              <h2 className='text-xl font-semibold text-gray-900'>{subfix.ticket.ticket_title}</h2>
              <p className='text-gray-700'>{subfix.ticket.ticket_description}</p>
              <div className='space-y-2'>
                <hr className='border-gray-200 -mx-6' />
                {sections.map((section, index) => (
                  <TicketSection
                    key={index}
                    title={section.title}
                    content={section.content}
                    isExpanded={expandedSections[index]}
                    onToggle={() => toggleSection(index)}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* <div className='mt-6 flex justify-between items-center'>
            <button className='flex items-center space-x-2 bg-[#171D300A] px-4 py-2 rounded-xl hover:bg-gray-200'>
              <FiDownload className='w-4 h-4 text-[#42485D99]' />
              <span className='text-[#171D30]'>Download all</span>
            </button>

            <div className='flex items-center gap-4'>
              <span className='text-md text-[#2C334766]'>Rate this explanation:</span>
              <button className='flex items-center gap-2 px-4 py-1 rounded-xl border border-gray-200 hover:bg-gray-50'>
                <MdThumbUp className='w-4 h-4 fill-[#42485D99]' />
                <span>good</span>
              </button>
              <button className='flex items-center gap-2 px-4 py-1 rounded-xl border border-gray-200 hover:bg-gray-50'>
                <MdThumbDown className='w-4 h-4 fill-[#42485D99]' />
                <span>poor</span>
              </button>
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default FixResolution;
