import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRefreshMutation } from "../../app/api/authApiSlice";
// import usePersist from "../../hooks/usePersist";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../app/authSlice";
// import { Spinner } from "../../components/functionality/Spinner";

const PersistLogin = () => {
  const token = useSelector(selectCurrentToken);
  const navigate = useNavigate();
  const [trueSuccess, setTrueSuccess] = useState(false);

  const [refresh, { isUninitialized, isLoading, isSuccess, isError, error }] = useRefreshMutation();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();
        setTrueSuccess(true);
      } catch (err) {
        console.error(err);
      }
    };

    if (!token) verifyRefreshToken();
  }, [refresh, token]);

  let content;
  if (isLoading) {
    content = <div className='flex items-center justify-center min-h-screen'>{/* <Spinner size='lg' /> */}</div>;
  } else if (isError) {
    console.error("Error in PersistLogin", error);
    navigate("/login");
  } else if (isSuccess && trueSuccess) {
    content = <Outlet />;
  } else if (token && isUninitialized) {
    content = <Outlet />;
  }
  return content;
};

export default PersistLogin;
