import React from "react";
import Fix from "../components/Fix";

function FixDetails({ selectedFix, doneCount }) {
  if (!selectedFix) {
    return (
      <div className='bg-gray-100 border border-gray-200 rounded-lg pt-40 pb-40 flex items-start justify-center text-lg min-h-[500px]'>
        <p className='text-gray-500'>Select a fix to view details</p>
      </div>
    );
  }

  return (
    <>
      <div className='px-6 py-3 flex items-center space-x-4'>
        <div>
          <span className='text-gray-700 font-light'>Fixes: </span>
          <span className='text-custom-gray font-light'>{doneCount} approved</span>
        </div>
      </div>
      <div className='flex flex-col  z-0 pl-4'>
        <div className='flex flex-col gap-4'>
          <Fix fix={selectedFix} />
        </div>
      </div>
    </>
  );
}

export default FixDetails;
