import React, { useState } from "react";
import Block from "../../../components/layout/Block";
import FixHeader from "./FixHeader";
import FixAISummary from "./FixAISummary";
import FixBody from "./FixBody";
import FixFooter from "./FixFooter";
import FixResolution from "./FixResolution";
import GenericButton from "../../../components/functionality/GenericButton";

const Fix = ({ fix, onFixClick, hasStack = false }) => {
  const [selectedFixIndex, setSelectedFixIndex] = useState(0);
  const currentFix = fix?.fixes?.[selectedFixIndex];

  return (
    <>
      <Block hasStack={hasStack}>
        {fix && <FixHeader fix={fix} />}
        <div className='mt-6'>{fix && <FixAISummary fix={fix} />}</div>

        {fix?.fixes && fix.fixes.length > 0 && (
          <div className='flex gap-2 mt-6'>
            {fix.fixes.map((subfix, index) => (
              <GenericButton
                key={subfix.id}
                onClick={() => setSelectedFixIndex(index)}
                innerText={subfix.title}
                dark={selectedFixIndex === index}
              />
            ))}
          </div>
        )}
        <div className='mt-6'>{fix && <FixBody fix={fix} subfix={currentFix} />}</div>
        <FixFooter fix={fix} onFixClick={onFixClick} />
      </Block>
      <FixResolution subfix={currentFix}></FixResolution>
    </>
  );
};

export default Fix;
