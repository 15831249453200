import React from "react";
import { FiUser, FiSettings, FiHelpCircle } from "react-icons/fi";
import Logout from "../../features/auth/Logout";

function ProfileMenu({ username, email }) {
  return (
    <div className='absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5'>
      <div className='px-4 py-3 border-b border-gray-100'>
        <p className='text-base font-medium text-gray-900'>{username || "Guest"}</p>
        <p className='text-sm text-gray-500 truncate'>{email || "No email"}</p>
      </div>
      {/* <a href='#profile' className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center'>
        <FiUser className='mr-3' size={18} /> Your Profile
      </a> */}
      {/* <a
        href='#settings'
        className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center'
      >
        <FiSettings className='mr-3' size={18} /> Settings
      </a> */}
      <a
        href='https://calendly.com/arthur-pentecoste/30-min-chat?month=2024-11'
        target='_blank'
        rel='noopener noreferrer'
        className=' px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center'>
        <FiHelpCircle className='mr-3' size={18} /> Help Center
      </a>
      <div className='border-t border-gray-100'></div>
      <div className='block px-4 py-2'>
        <Logout />
      </div>
    </div>
  );
}

export default ProfileMenu;
