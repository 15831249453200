import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import logoImage from "../../assets/icons/FocalStudio_round.png";

const loadingSteps = [
  "Initializing AI",
  "Analyzing brand data",
  "Generating insights",
  "Optimizing recommendations",
  "Preparing dashboard",
];

function LoadingPage() {
  const [currentStep, setCurrentStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const stepInterval = setInterval(() => {
      setCurrentStep((prevStep) =>
        prevStep < loadingSteps.length - 1 ? prevStep + 1 : prevStep
      );
    }, 400);

    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 1 : 100));
    }, 20);

    const navigationTimeout = setTimeout(() => {
      navigate("/dashboard");
    }, 2000);

    return () => {
      clearInterval(stepInterval);
      clearInterval(progressInterval);
      clearTimeout(navigationTimeout);
    };
  }, [navigate]);

  return (
    <div className='min-h-screen bg-white flex flex-col items-center justify-center p-4'>
      <div className='w-full max-w-md'>
        <motion.img
          src={logoImage}
          alt='Focal Studio Logo'
          className='w-20 h-20 mx-auto mb-12'
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.1 }}
        />
        <div className='bg-white rounded-lg shadow-lg p-8 border border-gray-200'>
          <div className='mb-8'>
            <div className='h-2 bg-gray-100 rounded-full overflow-hidden'>
              <motion.div
                className='h-2 bg-purple-600 rounded-full'
                initial={{ width: 0 }}
                animate={{ width: `${progress}%` }}
                transition={{ duration: 0.1 }}
              />
            </div>
          </div>
          {loadingSteps.map((step, index) => (
            <motion.div
              key={index}
              className='flex items-center mb-6'
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: index * 0.04 }}>
              <div
                className={`w-4 h-4 rounded-full mr-4 flex items-center justify-center ${
                  index < currentStep
                    ? "bg-green-500"
                    : index === currentStep
                    ? "bg-purple-600"
                    : "bg-gray-200"
                }`}>
                {index < currentStep && (
                  <svg
                    className='w-3 h-3 text-white'
                    fill='none'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    viewBox='0 0 24 24'
                    stroke='currentColor'>
                    <path d='M5 13l4 4L19 7'></path>
                  </svg>
                )}
              </div>
              <span
                className={`text-sm ${
                  index <= currentStep ? "text-gray-800 font-semibold" : "text-gray-400"
                }`}>
                {step}
              </span>
            </motion.div>
          ))}
        </div>
        <motion.p
          className='text-center mt-8 text-gray-600 text-sm'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}>
          Preparing your personalized dashboard...
        </motion.p>
      </div>
    </div>
  );
}

export default LoadingPage;
