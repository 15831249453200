import React from "react";

const BatchIssueButton = ({ issueNumber, dark = true, onClick }) => {
  const buttonStyle = dark
    ? "bg-[#171D30] text-white"
    : "bg-white text-[#171D30] border border-[#E9E9F0]";

  const dividerStyle = dark ? "border-[#5B5F6D]" : "border-[#E9E9F0]";

  const iconAndNumberStyle = dark ? "text-white" : "text-[#42485D] text-opacity-60";

  return (
    <button
      className={`w-[186px] h-[40px] rounded-xl flex items-center justify-center text-sm font-medium ${buttonStyle} transition-all duration-300 ease-in-out hover:shadow-lg hover:scale-[1.02] group/button`}
      onClick={onClick}>
      <span className={`pr-3 h-full flex items-center border-r ${dividerStyle}`}>
        Review & Fix
      </span>
      <div className={`flex items-center justify-center pl-3 h-full ${iconAndNumberStyle}`}>
        <svg
          className='mr-1 h-5 w-5 transition-all duration-300 ease-in-out group-hover/button:opacity-80'
          viewBox='0 0 28 28'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <rect
            x='2'
            y='2'
            width='14'
            height='14'
            rx='2'
            fill='currentColor'
            fillOpacity='0.3'
            className='transition-transform duration-300 group-hover/button:translate-x-[0.5px] group-hover/button:translate-y-[1px]'
          />
          <rect
            x='6'
            y='6'
            width='14'
            height='14'
            rx='2'
            fill='currentColor'
            fillOpacity='0.6'
          />
          <rect
            x='10'
            y='10'
            width='14'
            height='14'
            rx='2'
            fill='currentColor'
            className='transition-transform duration-300 group-hover/button:translate-x-[-0.5px] group-hover/button:translate-y-[-1px]'
          />
        </svg>
        {issueNumber}
      </div>
    </button>
  );
};

export default BatchIssueButton;
