import { createSlice } from "@reduxjs/toolkit";

const loadState = () => {
  try {
    const savedId = localStorage.getItem("selectedWebsiteId");
    return savedId || null;
  } catch (err) {
    return null;
  }
};

const websiteSlice = createSlice({
  name: "selectedWebsite",
  initialState: {
    selectedWebsiteId: loadState(),
  },
  reducers: {
    setSelectedWebsite: (state, action) => {
      state.selectedWebsiteId = action.payload;
      localStorage.setItem("selectedWebsiteId", action.payload);
    },
    clearSelectedWebsite: (state) => {
      state.selectedWebsiteId = null;
      localStorage.removeItem("selectedWebsiteId");
    },
  },
});

export const { setSelectedWebsite, clearSelectedWebsite } = websiteSlice.actions;
export default websiteSlice.reducer;
export const selectCurrentWebsiteId = (state) => state.selectedWebsite.selectedWebsiteId;
