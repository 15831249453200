import UkFlag from "../..//assets/flags/uk-flag.svg";
import EsFlag from "../..//assets/flags/es-flag.svg";
import DeFlag from "../..//assets/flags/de-flag.svg";
import FrFlag from "../..//assets/flags/fr-flag.svg";
import UsFlag from "../..//assets/flags/us-flag.svg";
import CaFlag from "../..//assets/flags/ca-flag.svg";

const RegionIndicator = ({ regions }) => {
  // Handle single region case
  const region = Array.isArray(regions) ? regions[0] : regions;

  const flags = {
    UK: UkFlag,
    ES: EsFlag,
    DE: DeFlag,
    FR: FrFlag,
    US: UsFlag,
    CA: CaFlag,
  };

  const getRegionLabel = (code) => {
    const labels = {
      UK: "UK",
      ES: "Spain",
      DE: "Germany",
      FR: "France",
      US: "United States",
      CA: "Canada",
    };
    return labels[code] || code;
  };

  return (
    <div className='flex items-center gap-2 bg-[#F8F8FB] px-2.5 py-0.5 rounded-full'>
      <img src={flags[region]} alt={getRegionLabel(region)} className='w-5 h-5 rounded-full' />
      <span className='text-gray-900'>{getRegionLabel(region)}</span>
    </div>
  );
};

export default RegionIndicator;
