import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StoreHealth from "../features/overview/StoreHealth";
import HealthCards from "../features/overview/HealthCards";
import OverviewFixes from "../features/overview/OverviewFixes";
import { useGetIssuesQuery } from "../app/api/issueApiSlice";
import { selectCurrentWebsiteId } from "../app/selectedWebsiteSlice";
import { useGetWebsitesFromUserQuery } from "../app/api/websiteApiSlice";
import { useGetUserQuery } from "../app/api/userApiSlice";
import useAuth from "../hooks/useAuth";
import { useGetShouldShowSurveyQuery } from "../app/api/userApiSlice";
import UserSurvey from "../features/users/UserSurvey";
import { useMarkSurveyShownMutation } from "../app/api/userApiSlice";
import { useNavigate } from "react-router-dom";

function OverviewPage() {
  const navigate = useNavigate();
  const [showSurvey, setShowSurvey] = useState(false);
  const [markSurveyShown] = useMarkSurveyShownMutation();

  const selectedWebsiteId = useSelector(selectCurrentWebsiteId);

  const { id: userId } = useAuth();
  const { data: user } = useGetUserQuery(userId);

  const { selectedWebsite } = useGetWebsitesFromUserQuery(
    { user_id: userId },
    {
      skip: !userId,
      selectFromResult: ({ data }) => ({
        selectedWebsite: data?.entities?.[selectedWebsiteId] ?? null,
      }),
    }
  );

  const { data: shouldShowSurvey } = useGetShouldShowSurveyQuery({ userId });

  const { websiteIssues } = useGetIssuesQuery(
    { website_id: selectedWebsiteId },
    {
      skip: !selectedWebsiteId,
      selectFromResult: ({ data }) => ({
        websiteIssues: Object.values(data?.entities ?? {}),
      }),
    }
  );

  useEffect(() => {
    if (shouldShowSurvey?.available_surveys?.length > 0 && selectedWebsiteId && userId) {
      const availableSurvey = shouldShowSurvey.available_surveys[0];

      // Check if current website is in matching websites

      setShowSurvey(true);
      try {
        markSurveyShown({
          userId,
          templateId: availableSurvey.template.id,
        })
          .unwrap()
          .then(() => console.log("Survey marked as shown successfully"))
          .catch((err) => console.error("Error marking survey:", err));
      } catch (err) {
        console.error("Error in markSurveyShown:", err);
      }
    }
  }, [shouldShowSurvey, markSurveyShown, userId, selectedWebsiteId]);

  if (selectedWebsiteId && selectedWebsite) {
    let issueCount = 0;
    for (const metric in selectedWebsite.ai_agent_issue_counts) {
      issueCount += selectedWebsite.ai_agent_issue_counts[metric];
    }

    return (
      <div className='mx-auto px-2 py-4'>
        <StoreHealth website={selectedWebsite} issueCount={issueCount} />

        {shouldShowSurvey?.available_surveys?.length > 0 && (
          <div className='my-4 bg-[#7A16F8]/5 border border-[#7A16F8]/20 rounded-lg p-4 flex items-center justify-between'>
            <div>
              <h3 className='text-lg font-semibold text-[#7A16F8]'>We'd love your feedback!</h3>
              <p className='text-[#7A16F8]/80'>Help us improve FocalStudio by taking a quick survey</p>
            </div>
            <button
              onClick={() => navigate("/survey")}
              className='bg-[#7A16F8] hover:bg-[#7A16F8]/90 text-white px-6 py-2 rounded-lg shadow-sm'>
              Take Survey
            </button>
          </div>
        )}

        <HealthCards website={selectedWebsite} />
        <OverviewFixes fixes={websiteIssues} issueCount={issueCount} />
      </div>
    );
  }

  return <div>You do not have any store configured yet. Contact us to get started.</div>;
}

export default OverviewPage;
