import { FiExternalLink } from "react-icons/fi";
import SeverityIndicator from "../../../components/functionality/SeverityIndicator";
import RegionIndicator from "../../../components/functionality/RegionIndicator";
import FeedbackButton from "../../../components/functionality/FeedbackButton";

const FixHeader = ({ fix }) => {
  return (
    <div className='space-y-4'>
      <div className='flex justify-between items-start w-full gap-3'>
        <h1 className='text-2xl font-normal text-gray-900'>{fix.title}</h1>
        <FeedbackButton fix={fix} />
      </div>

      <div className='flex items-center gap-4'>
        <SeverityIndicator severity={fix.severity} />
        <span className='text-gray-600'>{fix.platforms.length > 1 ? "Desktop & Mobile" : fix.platforms[0]}</span>
        <RegionIndicator regions={fix.regions} />

        {fix.shortlink_url && fix.shortlink_title && (
          <a
            href={fix.shortlink_url}
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue-500 hover:text-blue-600 flex items-center ml-auto'>
            {fix.shortlink_title}
            <FiExternalLink className='ml-1' />
          </a>
        )}
      </div>
    </div>
  );
};

export default FixHeader;
