import React from "react";

//! Use previous code to render code

const CodeFix = ({ data }) => {
  return (
    <div className='space-y-4'>
      <div className='grid grid-cols-2 gap-8'>
        <div>
          <h3 className='text-gray-500 mb-2'>Updated Code:</h3>
          <pre className='p-4 bg-[#171D30] text-[#FFFFFF] rounded-lg overflow-x-auto whitespace-pre-wrap'>
            <code>{data.content.after || "No updated code"}</code>
          </pre>
        </div>

        <div>
          <h3 className='text-gray-500 mb-2'>Original Code:</h3>
          <pre className='p-4 bg-[#171D30] text-[#FFFFFF] rounded-lg overflow-x-auto whitespace-pre-wrap'>
            <code>{data.content.before || "No original code"}</code>
          </pre>
        </div>
      </div>
      {data.diff && (
        <div className='mt-4'>
          <h3 className='text-gray-500 mb-2'>Diff:</h3>
          <pre className='p-4 bg-[#171D30] text-[#FFFFFF] rounded-lg overflow-x-auto whitespace-pre-wrap'>
            <code>{data.diff}</code>
          </pre>
        </div>
      )}
    </div>
  );
};

export default CodeFix;
