import React from "react";

const ImageFix = ({ data }) => {
  const truncateText = (text, maxLength = 62) => {
    if (!text || text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  const renderDescription = (title, description) => {
    if (title?.toLowerCase().includes("url") || title?.toLowerCase().includes("href")) {
      return (
        <a
          href={description}
          className='text-blue-600 text-sm hover:underline break-all'
          target='_blank'
          rel='noopener noreferrer'>
          {truncateText(description)}
        </a>
      );
    }
    return <p className='text-sm text-gray-600 break-all'>{truncateText(description)}</p>;
  };

  return (
    <div className='space-y-4'>
      <div className='flex mb-4'>
        <div className='w-1/2 pr-2 flex flex-col'>
          <h3 className='font-normal mb-2'>Updated:</h3>
          <div className='border border-gray-300 rounded-lg overflow-hidden flex-1 flex flex-col'>
            <div className='flex-grow'>
              <img
                src={data.content.after}
                alt='Updated version'
                className='w-full h-full max-h-[350px] object-contain'
              />
            </div>
            {data.subtext?.after && (
              <div className='p-3 border-t border-gray-300 bg-gray-50'>
                <p className='font-medium'>{data.subtext.after.title}</p>
                {renderDescription(data.subtext.after.title, data.subtext.after.description)}
              </div>
            )}
          </div>
        </div>

        <div className='w-1/2 pl-2 flex flex-col'>
          <h3 className='font-normal text-gray-400 mb-2'>Original:</h3>
          <div className='border border-gray-300 rounded-lg overflow-hidden flex-1 flex flex-col'>
            <div className='flex-grow'>
              <img
                src={data.content.before}
                alt='Original version'
                className='w-full h-full max-h-[350px] object-contain'
              />
            </div>
            {data.subtext?.before && (
              <div className='p-3 border-t border-gray-300 bg-gray-50'>
                <p className='font-medium'>{data.subtext.before.title}</p>
                {renderDescription(data.subtext.before.title, data.subtext.before.description)}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageFix;
