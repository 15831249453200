import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiBell, FiChevronDown, FiUser } from "react-icons/fi";
import { ReactComponent as FocalLogo } from "../../assets/icons/focal-logo.svg";
import homeIcon from "../../assets/icons/header/home-line.svg";
import upgradesIcon from "../../assets/icons/header/upgrades-line.svg";
import integrationsIcon from "../../assets/icons/header/integrations-line.svg";
import ProfileMenu from "./ProfileMenu";
import BrandSelector from "./BrandSelector";
import useAuth from "../../hooks/useAuth";

const Header = () => {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const profileRef = useRef(null);
  const { username, email } = useAuth();

  useEffect(() => {
    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsProfileMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef]);

  return (
    <header className='bg-appBackground border-b-2 border-separator relative z-10'>
      <div className='px-6 py-4 flex justify-between items-center'>
        <div className='flex items-center space-x-8'>
          <Link to='/dashboard'>
            <FocalLogo className='h-8 w-8 object-contain cursor-pointer' />
          </Link>
          <nav>
            <ul className='flex space-x-6'>
              <li>
                <Link
                  to='/dashboard'
                  className='text-gray-600 hover:text-gray-900 flex items-center'>
                  <img src={homeIcon} alt='Home' className='mr-2 w-5 h-5' />
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to='/fixes'
                  className='text-gray-600 hover:text-gray-900 flex items-center'>
                  <img src={upgradesIcon} alt='Upgrades' className='mr-2 w-5 h-5' />
                  Fixes
                </Link>
              </li>
              <li>
                <Link
                  to='/integrations'
                  className='text-gray-600 hover:text-gray-900 flex items-center'>
                  <img src={integrationsIcon} alt='Integrations' className='mr-2 w-5 h-5' />
                  Integrations
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className='flex items-center space-x-6'>
          <BrandSelector />
          <a
            href='https://calendly.com/arthur-pentecoste/30-min-chat'
            target='_blank'
            rel='noopener noreferrer'
            className='text-gray-600 hover:text-gray-900'>
            Contact Support
          </a>
          <FiBell className='text-gray-600 hover:text-gray-900 cursor-pointer' size={20} />
          <div className='relative' ref={profileRef}>
            <div
              className='flex items-center space-x-2 cursor-pointer'
              onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}>
              <div className='bg-gray-200 rounded-full p-2'>
                <FiUser className='text-gray-600' size={20} />
              </div>
              <span className='text-gray-700'>{email || 'Guest'}</span>
              <FiChevronDown className='text-gray-500' size={12} />
            </div>
            {isProfileMenuOpen && (
              <div className='absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50'>
                <ProfileMenu username={username} email={email} />
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
