import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Spinner } from "../../../../components/functionality/Spinner";
import { FiExternalLink } from "react-icons/fi";

const ImageFix = ({ data, fixTitle }) => {
  const truncateText = (text, maxLength = 62) => {
    if (!text || text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  const renderDescription = (title, description) => {
    if (title?.toLowerCase().includes("url") || title?.toLowerCase().includes("href")) {
      return (
        <a
          href={description}
          className='text-blue-600 text-sm hover:underline break-all'
          target='_blank'
          rel='noopener noreferrer'>
          {truncateText(description)}
        </a>
      );
    }
    return <p className='text-sm text-gray-600 break-all'>{description}</p>;
  };

  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  React.useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [showModal]);

  React.useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === "Escape") {
        setShowModal(false);
      }
    };

    if (showModal) {
      document.addEventListener("keydown", handleEscapeKey);
    }

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [showModal]);

  const handleImageLoad = () => setIsLoading(false);

  const handleImageClick = (imageData) => {
    setShowModal(true);
  };

  const modalContent = showModal && (
    <div
      className='fixed inset-0 bg-black bg-opacity-90 z-[9999] flex items-start justify-center p-4'
      onClick={() => setShowModal(false)}>
      <div
        className='max-w-[90vw] w-full max-h-[97vh] overflow-y-auto bg-white rounded-lg'
        onClick={(e) => e.stopPropagation()}>
        <div className='sticky top-0 bg-white z-[10000] flex justify-between items-center p-5 pb-3 border-b border-gray-200'>
          <h2 className='text-2xl font-normal text-gray-900'>{fixTitle}</h2>
          <button onClick={() => setShowModal(false)} className='text-gray-500 hover:text-gray-700'>
            ✕
          </button>
        </div>

        <div className='rounded-lg p-6 pb-6 pt-3'>
          <div className='flex gap-4'>
            {/* Updated Version */}
            <div className='flex-1'>
              <div className='flex flex-row items-center justify-between mb-2'>
                <h3 className='text-lg font-medium'>Updated Version</h3>
                {data.link?.after?.title && (
                  <a
                    href={data.link.after.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-blue-500 hover:text-blue-600 flex items-center'>
                    {data.link.after.title}
                    <FiExternalLink className='ml-1' />
                  </a>
                )}
              </div>
              <div className='aspect-square w-full relative'>
                <img
                  src={data.content.after}
                  alt='Updated version'
                  className='absolute inset-0 w-full h-full object-contain border border-gray-200 rounded-lg'
                />
              </div>
              {data.subtext?.after && (
                <div className='mt-4 p-4 bg-gray-50 rounded-lg'>
                  <p className='font-medium'>{data.subtext.after.title}</p>
                  {renderDescription(data.subtext.after.title, data.subtext.after.description)}
                </div>
              )}
            </div>
            {/* Original Version */}
            <div className='flex-1'>
              <div className='flex flex-row items-center justify-between mb-2'>
                <h3 className='text-lg font-medium text-gray-400'>Original Version</h3>
                {data.link?.before?.title && (
                  <a
                    href={data.link.before.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-blue-500 hover:text-blue-600 flex items-center'>
                    {data.link.before.title}
                    <FiExternalLink className='ml-1' />
                  </a>
                )}
              </div>
              <div className='aspect-square w-full relative'>
                <img
                  src={data.content.before}
                  alt='Original version'
                  className='absolute inset-0 w-full h-full object-contain border border-gray-200 rounded-lg'
                />
              </div>
              {data.subtext?.before && (
                <div className='mt-4 p-4 bg-gray-50 rounded-lg'>
                  <p className='font-medium'>{data.subtext.before.title}</p>
                  {renderDescription(data.subtext.before.title, data.subtext.before.description)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className='space-y-4'>
      <div className='flex mb-4'>
        <div className='w-1/2 pr-2 flex flex-col'>
          <div className='flex flex-row items-center  gap-0'>
            <h3 className='font-normal mb-2'>Updated:</h3>
            {data?.link?.after?.title && (
              <a
                href={data?.link?.after?.url}
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue-500 hover:text-blue-600 flex items-center ml-auto'>
                {data?.link?.after?.title}
                <FiExternalLink className='ml-1' />
              </a>
            )}
          </div>
          <div
            className='border border-gray-300 rounded-lg overflow-hidden flex-1 flex flex-col group cursor-pointer relative'
            onClick={() =>
              handleImageClick({
                image: data.content.after,
                subtext: data.subtext?.after,
                link: data?.link?.after,
                type: "Updated",
              })
            }>
            <div className='flex-grow relative min-h-[290px]'>
              {isLoading && (
                <div className='absolute inset-0 bg-white flex items-center justify-center'>
                  <Spinner size='md' />
                </div>
              )}
              <div className='absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all flex items-center justify-center'>
                <span className='text-white opacity-0 group-hover:opacity-100 transition-opacity'>
                  Click to view fullscreen
                </span>
              </div>
              <img
                key={data.content.after}
                src={data.content.after}
                alt='Updated version'
                className='w-full h-full max-h-[350px] object-contain'
                onLoad={handleImageLoad}
                onError={handleImageLoad}
              />
            </div>
            {data.subtext?.after && (
              <div className='p-3 border-t border-gray-300 bg-gray-50'>
                <p className='font-medium'>{data.subtext.after.title}</p>
                {renderDescription(data.subtext.after.title, data.subtext.after.description)}
              </div>
            )}
          </div>
        </div>

        <div className='w-1/2 pl-2 flex flex-col'>
          <div className='flex justify-between items-center'>
            <h3 className='font-normal text-gray-400 mb-2'>Original:</h3>
            {data?.link?.before?.title && (
              <a
                href={data?.link?.before?.url}
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue-500 hover:text-blue-600 flex items-center ml-auto'>
                {data?.link?.before?.title}
                <FiExternalLink className='ml-1' />
              </a>
            )}
          </div>
          <div
            className='border border-gray-300 rounded-lg overflow-hidden flex-1 flex flex-col group cursor-pointer relative'
            onClick={() =>
              handleImageClick({
                image: data.content.before,
                subtext: data.subtext?.before,
                link: data?.link?.before,
                type: "Original",
              })
            }>
            <div className='flex-grow relative min-h-[290px]'>
              {isLoading && (
                <div className='absolute inset-0 bg-white flex items-center justify-center'>
                  <Spinner size='md' />
                </div>
              )}
              <div className='absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all flex items-center justify-center'>
                <span className='text-white opacity-0 group-hover:opacity-100 transition-opacity'>
                  Click to view fullscreen
                </span>
              </div>
              <img
                key={data.content.before}
                src={data.content.before}
                alt='Original version'
                className='w-full h-full max-h-[350px] object-contain'
                onLoad={handleImageLoad}
                onError={handleImageLoad}
              />
            </div>
            {data.subtext?.before && (
              <div className='p-3 border-t border-gray-300 bg-gray-50'>
                <p className='font-medium'>{data.subtext.before.title}</p>
                {renderDescription(data.subtext.before.title, data.subtext.before.description)}
              </div>
            )}
          </div>
        </div>
      </div>

      {showModal && ReactDOM.createPortal(modalContent, document.body)}
    </div>
  );
};

export default ImageFix;
