import React from "react";
import FixTypes from "../../../constants/fixTypes";
import ImageFix from "./fixTypes/ImageFix";
import TextFix from "./fixTypes/TextFix";
import CodeFix from "./fixTypes/CodeFix";
import RowFix from "./fixTypes/RowFix";

const FixBody = ({ fix, subfix }) => {
  const getFixComponent = (row) => {
    switch (row.type) {
      case FixTypes.IMAGE:
        return <ImageFix data={row.data} fixTitle={fix.title} />;
      case FixTypes.TEXT:
        return <TextFix data={row.data} />;
      case FixTypes.CODE:
        return <CodeFix data={row.data} />;
      case FixTypes.ROW:
        return <RowFix data={row.data} />;
      default:
        return null;
    }
  };

  return (
    <div className='space-y-6'>
      {subfix?.rows?.map((row, index) => (
        <div key={`${row.type}-${index}`}>{getFixComponent(row)}</div>
      ))}
    </div>
  );
};

export default FixBody;
