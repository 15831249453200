import { ReactComponent as FocalLogo } from "../../assets/icons/focal-logo.svg";
import "./spinner.css";

export const Spinner = ({ size = "md" }) => {
  const sizeMap = {
    sm: "32",
    md: "48",
    lg: "64",
  };

  return <FocalLogo className='rotatingLogo' width={sizeMap[size]} height={sizeMap[size]} />;
};
