import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentWebsiteId } from "../app/selectedWebsiteSlice";
import { useGetIssuesQuery } from "../app/api/issueApiSlice";
import { useCreateUserActionMutation, trackUserAction } from "../app/api/userActionApiSlice";
import { ActionType } from "../constants/actionTypes";
import { FiChevronLeft } from "react-icons/fi";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { ReactComponent as KeyLeftIcon } from "../assets/icons/navigation/keyLeft.svg";
import { ReactComponent as KeyRightIcon } from "../assets/icons/navigation/keyRight.svg";
import FixesList from "../features/fixes/fixesPage/FixesList";
import FixDetails from "../features/fixes/fixesPage/FixDetails";

const FixPage = () => {
  const navigate = useNavigate();
  const { fixId } = useParams();
  const selectedWebsiteId = useSelector(selectCurrentWebsiteId);
  const [createUserAction] = useCreateUserActionMutation();

  const { websiteIssues: fixes, isLoading } = useGetIssuesQuery(
    { website_id: selectedWebsiteId },
    {
      skip: !selectedWebsiteId,
      selectFromResult: ({ data, isLoading }) => ({
        websiteIssues: Object.values(data?.entities ?? {}),
        isLoading,
      }),
    }
  );

  const [filteredFixes, setFilteredFixes] = useState([]);
  const [selectedFix, setSelectedFix] = useState(null);
  const [hasTrackedInitialView, setHasTrackedInitialView] = useState(false);

  const currentFixIndex = filteredFixes.findIndex((fix) => fix?.id === selectedFix?.id);

  const doneCount = useMemo(() => {
    if (!fixes) return 0;
    return fixes.reduce((acc, fix) => (fix.status === "done" ? acc + 1 : acc), 0);
  }, [fixes]);

  const handleSelectFix = useCallback(
    (fix) => {
      setSelectedFix(fix);
      navigate(`/fixes/${fix.id}`);
    },
    [navigate]
  );

  const handlePreviousFix = useCallback(() => {
    if (currentFixIndex > 0) {
      handleSelectFix(filteredFixes[currentFixIndex - 1]);
    }
  }, [currentFixIndex, filteredFixes, handleSelectFix]);

  const handleNextFix = useCallback(() => {
    if (currentFixIndex < filteredFixes.length - 1) {
      handleSelectFix(filteredFixes[currentFixIndex + 1]);
    }
  }, [currentFixIndex, filteredFixes, handleSelectFix]);

  useEffect(() => {
    if (fixes.length > 0 && !hasTrackedInitialView) {
      if (fixId) {
        const fix = fixes.find((f) => f.id === fixId);
        if (fix) {
          setSelectedFix(fix);
          trackUserAction(createUserAction, ActionType.PAGE_VIEW, {
            issue_id: fix.id,
            viewed_page: "issue",
          });
        } else {
          setSelectedFix(fixes[0]);
          trackUserAction(createUserAction, ActionType.PAGE_VIEW, {
            issue_id: fixes[0].id,
            viewed_page: "issue",
          });
        }
      } else {
        setSelectedFix(fixes[0]);
        trackUserAction(createUserAction, ActionType.PAGE_VIEW, {
          issue_id: fixes[0].id,
          viewed_page: "issue",
        });

        if (filteredFixes.length === 0) {
          setFilteredFixes(fixes);
        }
      }
      setHasTrackedInitialView(true);
    } else if (fixes.length === 0) {
      setSelectedFix(null);
      setHasTrackedInitialView(false);
    }
  }, [fixId, fixes, selectedWebsiteId, createUserAction, hasTrackedInitialView]);

  const handleFixesFiltered = useCallback(
    (newFilteredFixes) => {
      if (JSON.stringify(newFilteredFixes) !== JSON.stringify(filteredFixes)) {
        setFilteredFixes(newFilteredFixes);
      }
    },
    [filteredFixes]
  );

  //! SCROLL TO TOP OF FIX DETAILS PAGE
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [selectedFix]);

  //! NAVIGATION CONTROLS
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.target.tagName === "INPUT" || event.target.tagName === "TEXTAREA") {
        return;
      }

      if (event.key === "ArrowLeft" && currentFixIndex > 0) {
        handlePreviousFix();
      } else if (event.key === "ArrowRight" && currentFixIndex < filteredFixes.length - 1) {
        handleNextFix();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentFixIndex, filteredFixes.length, handlePreviousFix, handleNextFix]);

  return (
    <div className='flex w-full min-h-[2000px]'>
      <div className='w-[30%] sticky top-[15px] h-screen'>
        <FixesList
          fixes={fixes}
          selectedFix={selectedFix}
          onFixClick={handleSelectFix}
          onFixesFiltered={handleFixesFiltered}
        />
      </div>
      <div className='w-[73%] flex-col'>
        <FixDetails selectedFix={selectedFix} doneCount={doneCount} />
        {/* NAVIGATION CONTROLS */}
        <div className='mt-6 flex justify-between items-center'>
          <button
            className='flex justify-center items-center gap-0.5 px-2.5 py-1.5 rounded-lg  text-gray-600 hover:text-gray-800 transition-colors duration-200'
            onClick={() => navigate("/dashboard")}>
            <FiChevronLeft className='w-5 h-5' /> Back to overview
          </button>
          <div className='flex items-center space-x-6'>
            <div className='flex items-center'>
              <span className='text-[rgba(44,51,71,0.40)] text-center font-poppins text-[14px] font-normal leading-[14px] mr-2'>
                Navigation:
              </span>
              <KeyLeftIcon className='w-8 h-8 mr-1' />
              <KeyRightIcon className='w-8 h-8 mr-1' />
            </div>
            <button
              onClick={handlePreviousFix}
              disabled={currentFixIndex === 0}
              className='text-[#42485D99] disabled:text-[#171D3040] transition-colors duration-200'>
              <FaArrowLeft className='w-5 h-5 scale-x-[0.8] scale-y-[0.8]' />
            </button>
            <span className='text-sm font-medium text-[#2C334766]'>
              {currentFixIndex + 1} of {filteredFixes.length}
            </span>
            <button
              onClick={handleNextFix}
              disabled={currentFixIndex === filteredFixes.length - 1}
              className='text-[#42485D99] disabled:text-[#171D3040] transition-colors duration-200'>
              <FaArrowRight className='w-5 h-5 scale-x-[0.8] scale-y-[0.8]' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixPage;
