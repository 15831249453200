import React from "react";
import { InlineWidget } from "react-calendly";
import { FaCalendarAlt } from "react-icons/fa";
import Logo from "../assets/Logo.svg";
import Footer from "../components/layout/Footer";

const WorkingOnSomething = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Header with Logo and Title */}
      <header className="bg-white border-b border-gray-200">
        <div className="container mx-auto px-4 h-[72px] flex items-center">
          <a 
            href="https://www.focalstudio.ai" 
            className="flex items-center gap-3 hover:opacity-80 transition-opacity"
          >
            <img src={Logo} alt="FocalStudio Logo" className="h-8" />
            <span className="text-xl font-regular text-gray-900">Focal Studio</span>
          </a>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-1 flex items-center bg-gray-50">
        <div className='container mx-auto px-4 py-16'>
          <div className='max-w-7xl mx-auto'>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-12'>
              {/* Left Column - Text Content */}
              <div className='flex flex-col justify-center space-y-8'>
                <div>
                  <h1 className='text-4xl font-regular text-gray-900 mb-6'>
                    Contact Our Sales Team
                  </h1>
                  <p className='text-xl text-gray-500'>
                    Ready to optimize your e-commerce store? Schedule a call with our team to discuss how Focal Studio can help streamline your content operations.
                  </p>
                </div>
                
                <div className='bg-white border border-gray-200 rounded-lg p-8'>
                  <div className='flex items-center gap-3 mb-4'>
                    <FaCalendarAlt className="text-2xl" />
                    <h2 className='text-2xl font-regular text-gray-900'>Book Your Consultation</h2>
                  </div>
                  <p className='text-lg font-light text-gray-500'>
                    Choose a time that works best for you. Our sales team will walk you through 
                    FocalStudio's capabilities and discuss your specific requirements.
                  </p>
                </div>
              </div>

              {/* Right Column - Calendar */}
              <div className='bg-white border border-gray-200 rounded-lg h-[700px]'>
                <InlineWidget 
                  url='https://calendly.com/arthur-pentecoste/30-min-chat'
                  styles={{ height: '100%', minHeight: '700px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default WorkingOnSomething;
